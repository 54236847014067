<template>
	<div :class="{'is-invalid': validation?.$error}">
		<div class="d-flex align-items-center mb-1">
			<label class="form-label mb-0 fw-bold lh-sm" :for="inputId">{{ title }}</label>
			<div class="ms-auto" v-if="subtitle">
				<span class="text-sm text-muted">{{ subtitle }}</span>
			</div>
		</div>
		<slot />
		<span class="mt-1 invalid-feedback fw-bold" v-for="error of validation?.$errors ?? []" :key="error.$uid">{{ error.$message }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			inputId: String,
			title: String,
			subtitle: String,
			validation: Object
		}
	}
</script>