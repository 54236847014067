<template>
	<tr class="position-relative">
		<td class="p-0" width="50">
			<CommonImageAvatar :src="imageUrl" size="md" />
		</td>
		<td class="lh-1" :colspan="(user.pivot.admin) ? 1 : 2">
			<div class="vstack gap-1">
				<a href="javascript:void(0);" class="link-dark text-md fw-bold stretched-link" @click="options">{{ user.name }}</a>
				<div class="text-sm text-muted">{{ (user.publications_count == 0) ? 'Sin publicaciones' : `${user.publications_count} ${(user.publications_count == 1) ? 'publicación' : 'publicaciones'}` }}</div>
			</div>
		</td>
		<td class="text-end py-0" v-if="user.pivot.admin">
			<span class="badge rounded-pill bg-success text-xs">Admin.</span>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		emits: ['options'],
		props: {
			user: Object,
			edit: Boolean
		},
		setup(props, { emit }) {
			const options = () => {
				if(props.edit) emit('options', props.user);
			}

			const imageUrl = computed(() => {
				if(props.user.avatar_url) return props.user.avatar_url;
				else return assetsImgIconsNoImage;
			});

			return { options, imageUrl };
		}
	}
</script>