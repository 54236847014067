<template>
	<nav class="mb-1" aria-label="breadcrumb" v-if="breadcrumb && breadcrumbs.length > 0">
		<ol class="breadcrumb">
			<li class="breadcrumb-item" v-for="(text, index) in breadcrumbs" v-bind:key="index">{{ text }}</li>
		</ol>
	</nav>
	<h2 class="lh-xs" v-html="text"></h2>
	<div class="text-sm lh-sm" v-if="subtext" v-html="subtext"></div>
</template>

<script>
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';

	export default {
		props: {
			text: String,
			subtext: String,
			breadcrumb: {
				type: Boolean,
				default: false
			}
		},
		setup() {
			const route = useRoute();

			const breadcrumbs = computed(() => {
				let data = [];
				route.matched.forEach((route) => {
					if(route?.meta?.breadcrumb) data.push(route.meta.breadcrumb);
				});

				return data;
			});

			return { breadcrumbs };
		}
	}
</script>