<template>
	<LayoutClean class="vstack justify-content-center bg-primary">
		<div class="container-sm mh-100 py-10 px-8">
			<div class="vstack gap-8">
				<div class="hstack justify-content-between align-items-end">
					<!-- Logo -->
					<router-link :to="{ name: 'home' }">
						<img src="@/assets/img/logo/complete-white.png" class="h-rem-14" alt="logo">
					</router-link>
					<div>
						<UserCardMenu :user="user" v-if="hasUser" />
						<UserCardMenuEmpty v-else />
					</div>
				</div>
				<div class="card text-bg-white border-0 rounded-4 shadow-none">
					<div class="card-body vstack gap-2 p-6 text-center">
						<div class="ls-tight lh-xs text-md">Si te gusta nuestra plataforma o tienes alguna duda ¡no dudes en contactarnos!</div>
						<a :href="`mailto:${$env.CONTACT_EMAIL}`" class="link-primary fw-bold">{{ $env.CONTACT_EMAIL }}</a>
					</div>
				</div>
			</div>
		</div>
	</LayoutClean>
</template>

<script>
	import { computed, onActivated, onDeactivated } from 'vue';
	import store from '@/store';

	export default {
		name: 'ViewsHome',
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const themeColor = document.querySelector('meta[name="theme-color"]').content;
			const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--x-primary');

			onActivated(() => {
				document.querySelector('meta[name="theme-color"]').content = primaryColor;
			});

			onDeactivated(() => {
				document.querySelector('meta[name="theme-color"]').content = themeColor;
			});

			return { hasUser, user };
		}
	}
</script>