<template>
	<SearchFilter :user="user" :prefilters="user?.filters" />
</template>

<script>
	export default {
		props: {
			user: Object
		}
	}
</script>