<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<textarea
				:id="inputId"
				:value="modelValue"
				@input="emitInput"
				class="form-control"
				:class="{'is-invalid': validation?.$error}"
				:placeholder="placeholder"
				:rows="rows"
				v-focus="focus"></textarea>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object,
			rows: {
				type: Number,
				default: 3
			},
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);

			const emitInput = (event) => {
				inputValue.value = event.target.value;
				emit('update:modelValue', inputValue.value);
			};

			return { inputId, inputValue, emitInput };
		}
	}
</script>