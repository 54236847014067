<template>
	<LayoutLoading v-if="!loaded" />
	<LayoutClean class="vstack justify-content-center" v-else>
		<main class="container-sm py-10">
			<div class="vstack gap-10">
				<div class="text-center">
					<router-link :to="{ name: 'home' }" class="d-inline-block">
						<img src="@/assets/img/logo/complete.png" class="h-rem-12" alt="logo">
					</router-link>
				</div>
				<div class="card text-bg-white rounded-4 border-2 border-dark">
					<h5 class="card-header border-0">{{ title }}</h5>
					<div class="card-body pt-0 pb-2">
						<p class="lh-sm" v-html="description"></p>
						<div class="vstack gap-1 mt-5" v-if="rules.length > 0">
							<!-- Title -->
							<h6 class="text-muted">Reglas de la invitación</h6>
							<!-- List group -->
							<ul class="list-group list-group-flush">
								<li class="list-group-item py-1" :class="(rulesPending.includes('logged')) ? '' : 'text-success'" v-if="rules.includes('logged')"><i class="bi pe-2" :class="(rulesPending.includes('logged')) ? 'bi-x-circle' : 'bi-check-circle'"></i>Debes estar logueado</li>
								<li class="list-group-item py-1" :class="(rulesPending.includes('email_verified')) ? '' : 'text-success'" v-if="rules.includes('email_verified')"><i class="bi bi-check-circle pe-2" :class="(rulesPending.includes('email_verified')) ? 'bi-x-circle' : 'bi-check-circle'"></i>Debes tener un email verificado</li>
							</ul>
						</div>
					</div>
					<div class="card-footer hstack gap-3 border-0 justify-content-end">
						<button type="button" class="btn btn-danger rounded-pill" :disabled="submitting" @click="reject">Rechazar</button>
						<button type="button" class="btn btn-success rounded-pill" :disabled="submitting" @click="accept">Aceptar</button>
					</div>
				</div>
			</div>
		</main>
	</LayoutClean>
</template>

<script>
	import { ref, computed, onBeforeMount, getCurrentInstance } from 'vue';
	import { useRoute } from 'vue-router';
	import store from '@/store';
	import router from '@/router';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import InvitationRepository from '@/repositories/InvitationRepository';

	export default {
		setup(props) {
			const { submitting, catchError } = composableForm();
			const instance = getCurrentInstance();
			const route = useRoute();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const invitation = ref({});
			const loaded = ref(false);

			const canResponse = () => {
				if(!hasUser.value) {
					instance.proxy.$identify({ subtitle: 'Para responder las invitaciones', rules: (invitation.value?.rules ?? []) });
					return false;
				}
				
				if(hasUser.value && (invitation.value?.rules ?? []).includes('email_verified') && !user.value?.email_verified) {
					instance.proxy.$identify({ subtitle: 'Para responder las invitaciones', rules: (invitation.value?.rules ?? []), step: 'verify' });
					return false;
				}

				return true;
			}

			const title = computed(() => {
				switch(invitation.value?.invitationable_type) {
					case 'App\\Models\\Group':
						return 'Invitación de grupo';
						break;
					default:
						return 'Sin identificar';
						break;
				}
			});

			const description = computed(() => {
				switch(invitation.value?.invitationable_type) {
					case 'App\\Models\\Group':
						return `<b>${invitation.value.user.name}</b> te invitó a formar parte del grupo <b>${invitation.value.invitationable.name}</b>. ¿Deseas aceptar la invitación?`;
						break;
					default:
						return 'No se encontró un motivo de la invitación';
						break;
				}
			});

			const rules = computed(() => {
				let data = [];

				data.push('logged');
				if((invitation.value?.rules ?? []).includes('email_verified')) data.push('email_verified');

				return data;
			});

			const rulesPending = computed(() => {
				let data = [];

				if(!hasUser.value) data.push('logged');
				if((invitation.value?.rules ?? []).includes('email_verified') && !user.value?.email_verified) data.push('email_verified');

				return data;
			});

			const accept = async () => {
				if(!canResponse()) return;
				submitting.value = true;

				await InvitationRepository.accept(route.params.uuid).then((response) => {
					switch(invitation.value?.invitationable_type) {
						case 'App\\Models\\Group':
							store.dispatch('auth/me');
							router.push({ name: 'account.group.show', params: { id: invitation.value?.invitationable_id }});
							break;
						default:
							router.push({ name: 'home' });
							break;
					}
				}).catch((e) => {
					catchError(e, true);
				});

				submitting.value = false;
			}

			const reject = async () => {
				if(!canResponse()) return;
				submitting.value = true;

				await InvitationRepository.reject(route.params.uuid).then(() => {
					router.push({ name: 'home' });
				}).catch((e) => {
					catchError(e, true);
				});

				submitting.value = false;
			}

			onBeforeMount(() => {
				InvitationRepository.show(route.params.uuid).then((response) => {
					invitation.value = response.data;
					loaded.value = true;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});
			});

			return { route, submitting, invitation, accept, reject, loaded, title, description, rules, rulesPending };
		}
	}
</script>