<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="hstack gap-3 align-items-center">
			<CommonImageAvatar :src="imageSrc" size="lg" imageClass="border" />
			<label :for="inputId" class="btn btn-sm btn-neutral rounded-pill">
				<span>Seleccionar</span>
				<input type="file"
					accept="image/*"
					:id="inputId"
					@change="changeFile"
					class="visually-hidden">
			</label>
			<a href="javascript:void(0);" class="btn btn-sm btn-outline-danger rounded-pill" @click="remove" v-if="!!inputValue || !!imageUrl"><i class="bi bi-trash pe-2"></i>Eliminar</a>
		</div>
	</LayoutFormInputGeneral>

	<CommonModalImageProccessor ref="modalImageProccessor" title="Cambiar logo" subtitle="Ajustá la imagen para que quede dentro del recuadro" submitText="Terminar" submitClass="btn-danger" :rounded="rounded" @success="success" />
</template>

<script>
	import { ref, computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		emits: ['update:modelValue', 'remove'],
		props: {
			modelValue: String,
			imageUrl: String,
			title: String,
			subtitle: String,
			submitText: String,
			placeholder: String,
			validation: Object,
			rounded: Boolean
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);
			const modalImageProccessor = ref(null);

			const imageSrc = computed(() => {
				if(inputValue.value) return inputValue.value;
				else if(props.imageUrl) return props.imageUrl;
				else return assetsImgIconsNoImage;
			});

			const success = (data) => {
				inputValue.value = data;
				emit('update:modelValue', inputValue.value);
			};

			const remove = () => {
				inputValue.value = null;
				emit('update:modelValue', inputValue.value);
				emit('remove');
			};

			const toBase64 = (file) => new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			});

			const changeFile = (e) => {
				toBase64(e.target.files[0]).then((data) => modalImageProccessor.value.show({ file: data, aspect: 1 }));
				e.target.value = null;
			}

			return { inputId, inputValue, modalImageProccessor, imageSrc, success, remove, changeFile };
		}
	}
</script>