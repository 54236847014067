<template>
	<div class="avatar bg-white border rounded-circle overflow-hidden" :class="classes">
		<img :src="imageSrc" class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		props: {
			src: String,
			imageClass: String,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup(props) {
			const classes = [
				`avatar-${props.size}`,
				props.imageClass
			];

			const imageSrc = computed(() => {
				if(props.src) return props.src;
				else return assetsImgIconsNoImage;
			});

			return { classes, imageSrc };
		}
	}
</script>