<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Definir prioridad" id="account-publication-modal-priority">
		<template #body>
			<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
				<ul class="list-group list-group-flush rounded-4" v-if="publication?.id">
					<li class="list-group-item px-0 py-3" v-for="(priority, index) in $env.MODEL.publication.priorities" :key="index" :for="`exposed-${index}`">
						<label class="form-check">
							<input type="radio" :id="`exposed-${index}`" name="account-publication-modal-priority" :value="index" v-model="publication.priority" class="form-check-input">
							<div class="form-check-label text-dark d-block ms-1 lh-sm">
								<div class="d-flex justify-content-between">
									<div class="text-dark fw-bold">{{ priority.title }}</div>
									<div class="text-muted text-xs" v-if="priority.expires_in">Activo {{ priority.expires_in }} días</div>
								</div>
								<div>{{ priority.description }}</div>
							</div>
						</label>
					</li>
				</ul>
			</form>
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill me-auto" :disabled="submitting" @click="empty" v-if="publication?.priority">Quitar</button>
			<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="submit">Guardar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import AccountPublicationRepository from '@/repositories/Account/PublicationRepository';

	export default {
		emits: ['success'],
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();

			const offcanvas = ref(null);
			const publication = ref(null);
			const show = (data) => {
				publication.value = _.cloneDeep(data);
				offcanvas.value.offcanvas.show();
			}

			const empty = async () => {
				publication.value.priority = null;
			}

			const submit = async () => {
				if(submitting.value) return;
				submitting.value = true;

				await AccountPublicationRepository.update(publication.value.id, { priority: publication.value.priority }).then((response) => {
					offcanvas.value.offcanvas.hide();
					emit('success');
				}).catch((e) => catchError(e, true));

				submitting.value = false;
			}

			return { offcanvas, show, publication, empty, submitting, submit };
		}
	}
</script>