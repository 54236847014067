<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Superficie cubierta" subtitle="Opcional" :active="step == currentStep" :completed="min != null || max != null" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-12 hstack gap-3">
			<input type="number" min="0" step=".01" :value="min" @input="setValueMin" class="form-control" placeholder="Desde">
			<input type="number" min="0" step=".01" :value="max" @input="setValueMax" class="form-control" placeholder="Hasta">
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:min', 'update:max', 'setStep'],
		props: {
			min: Number,
			max: Number,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'extras.areas.covered';

			const setValueMin = (event) => {
				let value = event.target.value;
				emit('update:min', (value) ? Number(value) : null);
			}
			const setValueMax = (event) => {
				let value = event.target.value;
				emit('update:max', (value) ? Number(value) : null);
			}
			const empty = () => {
				emit('update:min', null);
				emit('update:max', null);
			}
			const setStep = () => emit('setStep', currentStep);

			const optionDescription = computed(() => {
				if(props.min > 0 && props.max > 0) return `Entre ${props.min} y ${props.max} m2`;
				else if(props.min > 0) return `Desde ${props.min} m2`;
				else if(props.max > 0) return `Hasta ${props.max} m2`;
				else return null;
			});

			return { currentStep, setValueMin, setValueMax, empty, setStep, optionDescription };
		}
	}
</script>