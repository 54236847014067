<template>
	<router-link :to="{ name: 'account.group.show', params: { id: group.id }}" class="hstack gap-2 align-items-center">
		<GroupCommonImage :src="group.logo_url" size="md" v-if="group?.logo_url" />
		<div class="flex-fill lh-xs text-limit">
			<div class="h3 text-break text-truncate">{{ group.name }}</div>
			<div class="text-muted text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			group: Object,
			subtitle: {
				type: String,
				default: null
			}
		}
	}
</script>