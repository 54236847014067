<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Locales" subtitle="Opcional" :active="step == currentStep" :completed="modelValue.length > 0" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-auto text-center" v-for="(option, index) in options" v-bind:key="index">
			<div class="form-item-checkable">
				<input class="form-item-check" type="checkbox" name="project-view" :value="option" :checked="modelValue.includes(option)" @input="setValue" :id="index">
				<label class="form-item w-100" :for="index">
					<span class="form-item-click form-control text-muted p-2 w-100 vstack gap-1 justify-content-between lh-sm">
						{{ option }}
					</span>
				</label>
			</div>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		emits: ['update:modelValue', 'setStep'],
		props: {
			modelValue: Array,
			step: String,
			form: Object,
			filters: Object
		},
		setup(props, { emit }) {
			const currentStep = 'extras.stock.places';

			const setValue = (event) => {
				let value = event.target.value,
					modelNew = _.clone(props.modelValue),
					index = _.indexOf(modelNew, value);

				if(index !== -1) modelNew.splice(index, 1);
				else modelNew.push(value);

				emit('update:modelValue', modelNew);
			}
			const empty = () => emit('update:modelValue', []);
			const setStep = () => emit('setStep', currentStep);

			const options = computed(() => props.filters?.categories[props.form.category]?.places ?? {});
			const optionDescription = computed(() => {
				if(props.modelValue.length > 0) return _.join(props.modelValue, ', ');
				else return null;
			});

			return { currentStep, setValue, empty, setStep, options, optionDescription };
		}
	}
</script>