<template>
	<tr>
		<td width="85" class="pe-0">
			<div class="position-relative avatar avatar-xl rounded-4 border border-2 border-dark" v-if="image">
				<img :src="image.file_url" class="object-cover position-absolute top-50 start-50 translate-middle" />
			</div>
		</td>
		<td class="lh-xs position-relative">
			<div class="vstack gap-1">
				<a href="javascript:void(0);" class="link-dark fw-bold stretched-link text-md" @click="select">{{ publication.title ?? 'Sin título' }}</a>
				<PublicationPartBadge :publication="publication" :scrollable="false" />
				<div class="d-flex" v-if="publication.priority"><PublicationPartPriority :priority="publication.priority" /></div>
			</div>
		</td>
		<td class="text-end py-0">
			<div class="dropstart">
				<button class="btn btn-md btn-square shadow-none border-0 rounded-circle text-md text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
				<div class="dropdown-menu px-0">
					<a href="javascript:void(0);" class="dropdown-item rounded-0 fw-bold" @click="select">Editar</a>
					<router-link :to="{ name: 'stat.publication.index', params: { id: publication.id }}" class="dropdown-item rounded-0 fw-bold">Superlikes</router-link>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
	import _ from 'lodash';

	export default {
		emits: ['select'],
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));

			const select = () => {
				emit('select', props.publication);
			}

			return { image, select };
		}
	}
</script>

<style scoped>
	.dropdown-menu {
		width: 180px;
	}
</style>