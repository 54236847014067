<template>
	<tr class="position-relative">
		<td width="50" class="pe-0">
			<div class="position-relative avatar avatar-md rounded-3" v-if="user.avatar_url">
				<img :src="user.avatar_url" class="object-cover position-absolute top-50 start-50 translate-middle" />
			</div>
		</td>
		<td class="lh-xs">
			<div class="vstack gap-1">
				<a href="javascript:void(0);" class="link-dark fw-bold stretched-link text-md" @click="select">{{ user.name }}</a>
				<div class="text-sm">{{ dateDiff }}</div>
			</div>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';
	import 'moment/locale/es';

	export default {
		emits: ['select'],
		props: {
			user: Object
		},
		setup(props, { emit }) {
			moment.locale('es');
			const dateDiff = moment(props.user.pivot.created_at).fromNow();

			const select = () => {
				emit('select', props.user);
			}

			return { dateDiff, select };
		}
	}
</script>