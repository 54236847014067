<template>
	<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
		<CommonFormInputEmail v-model="form.email" title="Email" placeholder="Ingrese su email" :validation="validation.email" />
	</form>
</template>

<script>
	import { reactive } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, email } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		emits: ['updateStep', 'updateEmail'],
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();

			const form = reactive({
				email: null
			});

			const validation = useVuelidate({
				email: {
					required,
					email
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await AuthRepository.check(form).then((response) => {
					emit('updateEmail', form.email);
					if(response.data.exists) emit('updateStep', 'recover');
					else emit('updateStep', 'signup');
				});
				submitting.value = false;
			}

			return { validation, form, submitting, submit };
		}
	}
</script>