<template>
	<div class="hstack gap-4 align-items-start">
		<PublicationCommonImage :src="image.file_url" size="lg" class="mx-auto" />
		<div class="vstack gap-1 lh-1 my-auto">
			<div class="fw-bold text-md" @click="select">{{ publication.title ?? 'Sin título' }}</div>
			<div v-if="publication.address">{{ publication.address.title }}</div>
			<div class="d-flex" v-if="publication.priority"><PublicationPartPriority :priority="publication.priority" /></div>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));

			return { image };
		}
	}
</script>