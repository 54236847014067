<template>
	<div class="h-100">
		<LayoutLoading text="¡Hasta luego!" />
	</div>
</template>

<script>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const timeout = ref(null);

			onMounted(() => timeout.value = setTimeout(() => store.dispatch('auth/logout'), 2000));
			onBeforeUnmount(() => { if(timeout.value) clearTimeout(timeout.value); });
		}
	}
</script>