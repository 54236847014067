<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Ubicación en mapa" :subtitle="(loaded) ? `${markers.length} publicaciones encontradas` : 'Cargando publicaciones'" headerClass="container-md pt-4 pb-3" bodyClass="container-fluid vstack gap-5 p-0" id="search-modal-map">
		<template #body>
			<CommonMapMap ref="mapRef" :center="center" mapStyle="height: 50vh; width: 100%;" mapClass="">
				<CommonMapCluster>
					<CommonMapMarkerPrice v-for="marker in markers" :key="marker.id" :position="{ lat: marker.address.geolocation.coordinates[0], lng: marker.address.geolocation.coordinates[1] }" :currency="marker.currency" :amount="marker.amount_sale ?? marker.amount" :priority="marker.priority" :id="marker.id" :vieweds="vieweds" @click="display(marker.id)" />
				</CommonMapCluster>
			</CommonMapMap>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import _ from 'lodash';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		emits: ['display'],
		props: {
			search: Object,
			vieweds: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();
			const hide = () => offcanvas.value.offcanvas.hide();
			const mapRef = ref(null);
			const loaded = ref(false);
			const markers = ref([]);

			let url = new URL(window.location.origin);
				url.pathname = '/img/icons/cluster';
				url.port = '';
			const baseClusterImage = url.toString();

			const center = ref({
				lat: -35.2,
				lng: -65.2
			});

			const getMarkers = () => {
				SearchRepository.markers(props.search.uuid).then((response) => {
					loaded.value = true;
					markers.value = response.data;
				});
			}

			const display = (id) => {
				emit('display', id);
			}

			onMounted(() => {
				document.getElementById('search-modal-map').addEventListener('show.bs.offcanvas', () => {
					if(!loaded.value) getMarkers();
				});
			});

			watch([() => mapRef.value?.mapRef.ready, markers], ([newReady, newMarkers]) => {
				if(newReady && (newMarkers ?? []).length > 0) {
					const bounds = new google.maps.LatLngBounds();
					_.forEach(newMarkers, (marker) => bounds.extend(new google.maps.LatLng(marker.address.geolocation.coordinates[0] ?? 0, marker.address.geolocation.coordinates[1] ?? 0)));
					mapRef.value.mapRef.map.fitBounds(bounds);
				}
			});

			return { baseClusterImage, offcanvas, loaded, show, hide, mapRef, center, markers, display };
		}
	}
</script>