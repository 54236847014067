<template>
	<router-view v-slot="{ Component }">
		<transition name="fade-fast" mode="out-in">
			<LayoutLoading text="Cargando cuenta" v-if="!loaded" />
			<component :is="Component" :user="user" v-else />
		</transition>
	</router-view>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import composableForm from '@/composables/form';
	import UserRepository from '@/repositories/UserRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const route = useRoute();
			const user = computed(() => store.getters['user/info']);
			const loaded = computed(() => (route.params.slug == user.value?.slug));

			onMounted(async () => {
				store.dispatch('redirect/setSearch', { name: 'user.search.index', params: { slug: route.params.slug }});

				if(!loaded.value) {
					await store.dispatch('user/empty');
					await UserRepository.show(route.params.slug).then((response) => {
						store.dispatch('user/setInfo', response.data);
					}).catch((e) => {
						catchError(e, true);
						return router.push({ name: 'home' });
					});
				}
			});

			return { user, loaded };
		}
	}
</script>