<template>
	<div ref="card" class="card border-0 rounded-0 h-100 images-place w-100 z-1 p-8 px-md-20 d-flex align-items-center">
		<div class="my-auto">
			<div class="vstack gap-5" v-if="count > 0">
				<h1 class="h1 lh-xs ls-tighter font-bolder">
					¿Te gustó lo que encontraste?
					<span class="d-block text-transparent bg-clip-text gradient-bottom-right start-indigo-500 end-cyan-500">¡Envia los resultados!</span>
				</h1>
				<p class="lh-sm text-lg font-regular">Ahora podés compartir tus búsquedas con otras personas, o simplemente enviar tu listado a quien vos quieras, enviándolo por whatsapp, email o cualquier otro medio.</p>
				<div class="hstack gap-3">
					<router-link :to="{ name: 'account.search.filtered', params: { uuid: route.params?.uuid }}" class="btn btn-lg btn-dark font-bold rounded-pill">Ver publicaciones</router-link>
					<button type="button" @click="share" class="btn btn-lg btn-primary font-bold rounded-pill"><i class="bi bi-share me-2"></i>Compartir</button>
				</div>
			</div>
			<div class="vstack gap-5" v-else>
				<h1 class="h1 lh-xs ls-tighter font-bolder">
					<span class="d-block text-transparent bg-clip-text gradient-bottom-right start-indigo-500 end-cyan-500">¡Ups!</span> Parece que no tuviste suerte.
				</h1>
				<p class="lh-sm text-lg font-regular">¡No hay problema! podés intentar con otra búsqueda :)</p>
				<div class="hstack gap-3">
					<router-link :to="{ name: 'search.index' }" class="btn btn-lg btn-dark font-bold rounded-pill">Nueva búsqueda</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';

	export default {
		emits: ['share'],
		props: {
			search: Object,
			count: Number
		},
		setup(props, { emit }) {
			const route = useRoute();

			const share = async (type) => {
				emit('share');
			}

			return { route, share };
		}
	}
</script>