<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Tienda" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper submitText="Guardar" :submitting="submitting" :errors="validationExternal" @submit="submit">
				<template #body>
					<CommonFormInputSlug v-model="form.slug" title="Slug de tu cuenta" subtitle="Obligatorio" :preppend="preppend" placeholder="Ingresa el slug" :focus="true" :validation="validation.slug" />
					<div class="alert alert-light lh-xs" role="alert">
						Una vez que habilites tu slug, quedará la dirección disponible, además podrás cargar publicaciones.
					</div>
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { reactive, computed } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import composableRedirect from '@/composables/redirect';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();
			const { redirectBack } = composableRedirect();
			const user = computed(() => store.getters['auth/user']);
			const preppend = computed(() => {
				return `${window.location.origin}/`;
			});

			const form = reactive({
				slug: user.value?.slug
			});

			const validation = useVuelidate({
				slug: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				await AuthRepository.updateSlug(form).then((response) => {
					store.dispatch('auth/setUser', response.data);
					redirectBack();
				}).catch(catchError);

				submitting.value = false;
			}

			const removeImage = () => {
				form.avatar_url = null;
			}

			return { preppend, form, submit, removeImage, submitting, validation, validationExternal };
		}
	}
</script>