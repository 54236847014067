<template>
	<form @submit.prevent="submit" class="card border-0 shadow-none">
		<div class="card-body vstack gap-8 px-0">
			<div class="vstack gap-5">
				<slot name="body"></slot>
			</div>
			<div class="text-center" v-if="errors.length > 0">
				<div class="invalid-feedback d-block m-0" v-for="(error, index) in errors" v-bind:key="index">{{ error }}</div>
			</div>
		</div>
		<div class="card-footer hstack gap-3 px-0 border-0 justify-content-end">
			<button type="button" class="btn btn-neutral rounded-pill" @click="cancel" :disabled="submitting">Cancelar</button>
			<button type="submit" class="btn btn-primary rounded-pill" :disabled="submitting">{{ submitText }}</button>
		</div>
	</form>
</template>

<script>
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		emits: ['submit'],
		props: {
			submitting: {
				type: Boolean,
				default: false
			},
			cancelUrl: {
				type: Object,
				default: null
			},
			errors: {
				type: Array,
				default: []
			},
			submitText: {
				type: String,
				default: 'Continuar'
			}
		},
		setup(props, { emit }) {
			const { redirectBack } = composableRedirect();
			const submit = () => emit('submit');
			const cancel = () => {
				if(props.cancelUrl) return router.push(props.cancelUrl);
				else redirectBack();
			}

			return { submit, cancel };
		}
	}
</script>