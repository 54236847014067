import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import VueScrollTo from 'vue-scrollto';
import _ from 'lodash';

export default function() {
	const toast = useToast();
	const submitting = ref(false);
	const loading = ref(true);
	const validationExternal = ref([]);

	const catchError = (e, asAlert = false) => {
		if(typeof e.response == 'undefined') {
			toast.error('Ocurrió un error con la petición.');
		}else if(e.response.data.message) {
			toast.error(e.response.data.message);
		}else switch(e.response.status) {
			case 404:
				toast.error('No se encontró la información que buscaba.');
				break;
			case 413:
				toast.error('El archivo que intenta subir es demasiado grande.');
				break;
			case 429:
				toast.error('Demasiados intentos, aguarde un momento y vuelva a intentar.');
				break;
			default:
				if(e.response && e.response.data) displayServerErrors(e.response.data, asAlert);
				else toast.error('No se pudo procesar su solicitud, por favor intente en unos momentos.');
				break;
		}

		submitting.value = false;
	}

	const displayServerErrors = (data, asAlert = false) => {
		var messages = (data.errors) ? _.map(data.errors, function(value) { return value[0]; }) : [data.message];

		if(asAlert) _.forEach(messages, function(message) { toast.error(message); });
		else{
			validationExternal.value = messages;
			setTimeout(() => VueScrollTo.scrollTo('#validation-external', 100, {offset: () => { return -20; }}), 200);
		}
	}

	return { submitting, loading, validationExternal, catchError, displayServerErrors };
}