<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Sincronizar publicaciones" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper submitText="Guardar" :cancelUrl="{ name: 'account.publication.index' }" :submitting="submitting" :errors="validationExternal" @submit="submit" v-if="!loading">
				<template #body>
					<LayoutFormInputGeneral inputId="type" title="Tipo de sincronización" :validation="validation.type">
						<div class="card bg-white shadow-1 border rounded-2">
							<label class="form-check m-3" for="type-json">
								<input type="radio" id="type-json" value="json" v-model="synchronizer.type" class="form-check-input">
								<div class="form-check-label text-dark ms-1 vstack lh-sm">
									<div class="d-flex justify-content-between align-items-center">
										<div class="fw-bold">Archivo JSON</div>
									</div>
									<small>Creá un archivo de tipo JSON accesible para nosotros, que diariamente vamos a sincronizar.</small>
								</div>
							</label>
							<hr class="my-0">
							<label class="form-check m-3" for="type-api">
								<input type="radio" id="type-api" value="api" v-model="synchronizer.type" class="form-check-input" disabled>
								<div class="form-check-label text-dark ms-1 vstack lh-sm">
									<div class="d-flex justify-content-between align-items-center">
										<div class="fw-bold">Mediante API</div>
										<div class="text-xs text-muted">Aún no disponible</div>
									</div>
									<small>Podés integrar a tu plataforma, nuestra API para realizar una sincronización rápida y robusta.</small>
								</div>
							</label>
						</div>
					</LayoutFormInputGeneral>
					<AccountSynchronizerTypeJson v-model="synchronizer.config" v-if="synchronizer.type == 'json'" />
					<div v-if="synchronizer.id">
						<a href="javascript:void(0);" class="link-danger" @click="modalRemove.show();"><i class="bi bi-trash pe-2"></i>Eliminar sincronización</a>
					</div>
				</template>
			</CommonFormWrapper>
		</main>
		<CommonModalAction ref="modalRemove" title="Eliminar sincronización" description="¿Desea eliminar esta sincronización? Al eliminarla, todas las publicaciones podrían quedar desactualizadas" submitText="Eliminar" submitClass="btn-danger" @success="destroy" />
	</LayoutMain>
</template>

<script>
	import { ref, reactive, onBeforeMount } from 'vue';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AccountSynchronizerRepository from '@/repositories/Account/SynchronizerRepository';

	export default {
		setup() {
			const { loading, submitting, validationExternal, catchError } = composableForm();

			const synchronizer = reactive({
				id: null,
				type: null,
				config: {}
			});

			const validation = useVuelidate({
				type: {
					required
				}
			}, synchronizer);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				await AccountSynchronizerRepository.update(synchronizer).then(() => {
					router.push({ name: 'account.publication.index' });
				}).catch(catchError);

				submitting.value = false;
			}

			const modalRemove = ref(null);
			const destroy = () => {
				AccountSynchronizerRepository.destroy().then(() => {
					router.push({ name: 'account.publication.index' });
				}).catch((e) => catchError(e, true));
			}

			onBeforeMount(async () => {
				await AccountSynchronizerRepository.show().then((response) => {
					synchronizer.id = response.data.id ?? null;
					synchronizer.type = response.data.type ?? 'json';
					synchronizer.config = response.data.config ?? {};
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: `account.publication.index` });
				});

				loading.value = false;
			});

			return { loading, synchronizer, submit, submitting, validation, validationExternal, modalRemove, destroy };
		}
	}
</script>