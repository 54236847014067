import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/account/search';
const toast = useToast();

export default {
	index(params) {
		return Repository.get(`${resource}`, { params: params });
	},
	destroy(id) {
		return Repository.delete(`${resource}/${id}`).then((response) => {
			toast.success(`Búsqueda eliminada correctamente.`);
			return response;
		});
	}
}