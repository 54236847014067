<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Opciones de la búsqueda" id="search-modal-option">
		<template #body>
			<div class="list-group list-group-flush gap-5">
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('list')">
					<div>
						<a href="javascript:void(0)" @click="list" class="d-block h5 font-semibold stretched-link">Ver publicaciones filtradas</a>
						<p class="text-sm lh-sm">
							<template v-if="count === null">Se mostrará el listado de publicaciones</template>
							<template v-else-if="count == 0">No hay publicaciones</template>
							<template v-else-if="count == 1">Hay 1 publicación</template>
							<template v-else>Hay {{ count }} publicaciones</template>
						</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('share')">
					<div>
						<a href="javascript:void(0)" @click="share" class="d-block h5 font-semibold stretched-link">Compartir búsqueda</a>
						<p class="text-sm lh-sm">Comparte la búsqueda con otras personas</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('edit') && search.pivot?.owner">
					<div>
						<a href="javascript:void(0)" @click="edit" class="d-block h5 font-semibold stretched-link">Editar búsqueda</a>
						<p class="text-sm lh-sm">Editá la información básica de la búsqueda</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>

	<SearchModalShare ref="modalShare" :search="search" />
	<SearchModalEdit ref="modalEdit" :search="search" v-if="search?.pivot?.owner" />
	<SearchModalMap ref="modalMap" :search="search" :vieweds="vieweds" @display="display" />
</template>

<script>
	import { ref, computed, getCurrentInstance } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import moment from 'moment';
	import 'moment/locale/es';

	export default {
		emits: ['display'],
		props: {
			search: Object,
			count: {
				type: Number,
				default: null
			},
			vieweds: {
				type: Array,
				default: []
			},
			hidden: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const instance = getCurrentInstance();
			const hasUser = computed(() => store.getters['auth/hasUser']);

			moment.locale('es');

			const modalShare = ref(null);
			const modalEdit = ref(null);
			const modalMap = ref(null);
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const dateDiff = (date) => moment(date).fromNow();

			const login = () => instance.proxy.$identify({ subtitle: 'Para utilizar las búsquedas' });

			const list = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();

				router.push({ name: 'account.search.filtered', params: { uuid: props.search.uuid ?? null }});
			}

			const share = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();

				modalShare.value.show();
			}

			const edit = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();
				else if(!props.search?.pivot?.owner) return toast.error('Para realizar esta acción debes haber creado esta búsqueda.');

				modalEdit.value.show();
			}

			const display = (id) => {
				emit('display', id);
			}

			return { modalShare, modalEdit, modalMap, offcanvas, login, show, login, list, share, edit, display, dateDiff };
		}
	}
</script>