<template>
	<tr>
		<td>{{ dateFormatted }}</td>
		<td>{{ search.title }}</td>
		<td>
			<UserCardSmall :user="search?.user" v-if="search?.user" />
			<GroupCardSmall :group="search?.group" v-else-if="search?.group" />
			<div v-else>En toda la plataforma</div>
		</td>
		<td><i class="bi" :class="{ 'bi-unlock': search.shareable, 'bi-lock': !search.shareable }"></i></td>
		<td class="text-end">
			<div class="avatar-group">
				<UserAvatarRounded v-for="user in search.users" :key="user.id" :user="user" size="xs" :title="user.name" />
			</div>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const dateFormatted = computed(() => moment(props.search.created_at).format('MMMM D [de] YYYY, HH:mm'));

			return { dateFormatted };
		}
	}
</script>