<template>
	<footer class="sticky-bottom vstack gap-4 text-center background-filter py-5">
		<UserCardHeader :user="user" />
		<div>
			<router-link :to="{ name: 'user.search.index', params: { slug: user.slug }}" class="btn btn-md btn-outline-dark border-2 fw-bold px-10 text-uppercase rounded-pill">Buscar</router-link>
		</div>
		<div class="text-sm">
			Powered by <router-link :to="{ name: 'home' }" class="link-primary">{{ viteAppName }}</router-link>
		</div>
	</footer>
</template>

<script>
	export default {
		props: {
			user: Object
		},
		setup() {
			const viteAppName = import.meta.env.VITE_APP_NAME;

			return { viteAppName };
		}
	}
</script>

<style scoped>
	.background-filter {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 1) 4.5rem);
	}
</style>