<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Lugares" subtitle="Opcional" :active="step == currentStep" :completed="modelValue.length > 0" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-12 vstack gap-2">
			<div class="input-group input-group-inline">
				<span class="input-group-text"><i class="bi bi-search"></i></span>
				<input v-model="search" class="form-control form-control-lg" placeholder="Buscar lugares...">
			</div>
			<div class="list-group" v-if="search && search.length >= minLength">
				<div class="list-group-item lh-xs text-truncate py-3" v-if="searching">Buscando <b>{{ search }}</b>...</div>
				<div class="list-group-item lh-xs text-truncate py-3" v-else-if="results.length == 0">No se encontraron resultados.</div>
				<a href="javascript:void(0);" class="list-group-item list-group-item-action lh-xs text-truncate py-3" v-for="(place, index) in results" @click="placeAdd(place.reference)" v-bind:key="index">{{ place.description }}</a>
			</div>
			<div class="text-md hstack gap-2 flex-wrap" v-if="modelValue && modelValue.length > 0">
				<button type="button" class="btn btn-md btn-neutral text-start lh-sm py-1" v-for="place in modelValue" v-bind:key="place.id" @click="placeRemove(place.id);">
					{{ place.name }}
					<span class="ms-1"><i class="bi bi-trash text-xs"></i></span>
				</button>
			</div>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed, watch } from 'vue';
	import _ from 'lodash';
	import MapRepository from '@/repositories/MapRepository';

	export default {
		emits: ['update:modelValue', 'setStep'],
		props: {
			modelValue: Array,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'places';
			const placesValue = ref(props.modelValue);
			const minLength = 3;
			const search = ref(null);
			const searching = ref(false);
			const results = ref({});

			const placeRemove = (id) => _.remove(placesValue.value, { id: id });
			const placeAdd = async (reference) => {
				await MapRepository.place({ reference: reference }).then((response) => {
					search.value = null;
					if(!_.some(placesValue.value, { id: response.data.id })) placesValue.value.push(response.data);
				});
			}

			const getResults = async (newSearch) => {
				await MapRepository.autocomplete({ search: newSearch }).then((response) => {
					results.value = response.data;
				});

				searching.value = false;
			}

			const setValue = () => emit('update:modelValue', placesValue.value);
			const empty = () => emit('update:modelValue', []);
			const setStep = () => emit('setStep', currentStep);

			const timeout = ref(null);
			watch(search, async (newValue) => {
				results.value = {};
				searching.value = true;
				if(timeout.value) clearTimeout(timeout.value);
				if(search.value && search.value.length >= minLength) timeout.value = setTimeout(() => getResults(newValue), 800);
			});
			watch(placesValue.value, setValue);

			const optionDescription = computed(() => {
				if(props.modelValue && props.modelValue.length == 1) return '1 Lugar';
				else if(props.modelValue && props.modelValue.length > 1) return `${props.modelValue.length} Lugares`;
				else return null;
			});

			return { currentStep, placesValue, placeRemove, placeAdd, minLength, search, searching, results, empty, setStep, optionDescription };
		}
	}
</script>