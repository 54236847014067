<template>
	<div class="vstack gap-1 text-center text-success">
		<div><i class="bi bi-check-circle display-2"></i></div>
		<div class="text-lg lh-sm">¡Bienvenido!</div>
	</div>
</template>

<script>
	import { onBeforeMount } from 'vue';
	import store from '@/store';
	import composableForm from '@/composables/form';

	export default {
		emits: ['updateStep', 'hide'],
		props: {
			rules: Array
		},
		setup(props, { emit }) {
			const { catchError } = composableForm();

			const setUser = () => {
				store.dispatch('auth/me').then((user) => {
					setTimeout(() => {
						if(props.rules.includes('email_verified') && !user.email_verified) emit('updateStep', 'verify');
						else emit('hide');
					}, 1000);
				}).catch(catchError);
			}

			onBeforeMount(setUser);
		}
	}
</script>