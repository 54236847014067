<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Compartir búsqueda" id="search-modal-share">
		<template #body>
			<p class="text-muted lh-sm">Armá búsquedas colaborativas junto a otras personas para encontrar aquello que buscan ¡más rápido que nunca!. Además podés enviarles el listado de aquellas publicaciones que te gustan, a quien desees.</p>
			<template v-if="search.pivot?.owner">
				<CommonFormInputRadio v-model="filtered" title="¿Querés aplicar algún filtro a los colaboradores?" :options="filtereds" />
				<div class="input-group input-group-inline border border-dark rounded-5" v-if="search.shareable">
					<input type="text" :value="shareAddress" class="form-control border-0 rounded-5 ps-4" aria-describedby="button-addon2">
				</div>
			</template>
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill me-auto" :disabled="submitting" @click="shareList">Enviar mi listado</button>
			<template v-if="search.pivot?.owner">
				<button type="button" class="btn btn-outline-danger rounded-pill" :disabled="submitting" @click="shareJoin(false)" v-if="search.shareable">Detener</button>
				<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="shareJoin(true)">Invitar</button>
			</template>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, watch, onMounted, inject } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { copyText } from 'vue3-clipboard';
	import { useToast } from 'vue-toastification';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		props: {
			search: Object
		},
		setup(props) {
			const $env = inject('$env');
			const toast = useToast();
			const filtered = ref(props.search.filtered);
			const { submitting, catchError } = composableForm();
			const filtereds = computed(() => _.mapValues($env.MODEL.search.filtereds, (value) => value.title));

			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const shareAddress = `${window.location.origin}${router.resolve({ name: 'search.result', params: { uuid: props.search.uuid }}).href}`;
			const copyAddress = () => {
				copyText(shareAddress, undefined, (error) => {
					if(error) toast.error(`La dirección no pudo ser copiada.`);
					else toast.success(`Dirección copiada correctamente.`);
				});
			}

			const shareJoin = async (shareable) => {
				if(!props.search?.pivot?.owner) return toast.error('Para realizar esta acción debes haber creado esta búsqueda.');

				submitting.value = true;

				if(props.search.shareable != shareable || props.search.filtered != filtered.value) {
					await SearchRepository.update(props.search.uuid, {
						shareable: shareable,
						filtered: filtered.value
					}).then((response) => {
						store.dispatch('auth/upsertSearch', response.data);
					}).catch((e) => catchError(e, true));
				}

				if(shareable) {
					if(!navigator.canShare) return toast.error(`Tu dispositivo no permite compartir contenido, prueba copiando el link de arriba.`);
					else navigator.share({
						title: props.search.title,
						text: 'Te invito a unirte a mi búsqueda :)',
						url: shareAddress
					});
				}

				submitting.value = false;
			}

			const shareList = () => {
				if(!navigator.canShare) return toast.error(`Tu dispositivo no permite compartir contenido.`);
				else SearchRepository.text(props.search.uuid).then((response) => {
					navigator.share({
						title: 'Mis publicaciones favoritas',
						text: response.data.text
					});
				}).catch((e) => catchError(e, true));
			}

			return { offcanvas, show, filtered, filtereds, shareAddress, copyAddress, submitting, shareList, shareJoin };
		}
	}
</script>