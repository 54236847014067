import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/account/synchronizer';
const toast = useToast();

export default {
	show() {
		return Repository.get(`${resource}`);
	},
	update(payload) {
		return Repository.put(`${resource}`, payload).then((response) => {
			toast.success(`Sincronización actualizada correctamente.`);
			return response;
		});
	},
	destroy() {
		return Repository.delete(`${resource}`);
	}
}