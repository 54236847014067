<template>
	<LayoutFormInputGeneral :title="info.title" v-if="info">
		<div class="text-md lh-xs" v-html="info.description"></div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { computed, inject } from 'vue';

	export default {
		props: {
			priority: Number
		},
		setup(props) {
			const $env = inject('$env');
			const info = computed(() => (props.priority in $env.MODEL.publication.priorities) ? $env.MODEL.publication.priorities[props.priority] : null);

			return { info };
		}
	}
</script>