<template>
	<div class="vstack gap-1 placeholder-glow">
		<h3 class="ls-tight placeholder col-5" :class="class" v-if="text"></h3>
		<div class="text-sm lh-sm placeholder col-6" :class="class" v-if="subtext"></div>
	</div>
</template>

<script>
	export default {
		props: {
			text: Boolean,
			subtext: Boolean,
			class: String
		}
	}
</script>