<template>
	<div class="hstack gap-2 align-items-center">
		<UserCommonImage :src="user?.avatar_url" size="xs" class="mx-auto" />
		<div class="flex-fill lh-xs text-limit">
			<div class="text-break text-truncate">{{ user?.name }}</div>
			<div class="link-dark text-md d-block text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			user: Object,
			subtitle: {
				type: String,
				default: null
			}
		}
	}
</script>