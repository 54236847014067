<template>
	<LayoutClean>
		<form class="container-md mh-100 py-10" @submit.prevent="submit" autocomplete="off">
			<div class="pb-4 vstack gap-4">
				<div>
					<router-link :to="{ name: 'home' }" class="d-inline-block">
						<img src="@/assets/img/logo/complete.png" class="h-rem-12" alt="logo">
					</router-link>
				</div>
				<p class="lh-xs">
					<h3>Términos y condiciones</h3>
					<br />
					Última actualización: 06/08/2024<br />
					<br />
					<h5>1. Aceptación de los Términos</h5>
					Al acceder y utilizar el sitio web Choizr.com (en adelante, "el Sitio"), usted acepta y se compromete a cumplir estos Términos y Condiciones (en adelante, "los Términos"). Si no está de acuerdo con estos Términos, no utilice el Sitio.<br />
					<br />
					<h5>2. Descripción del Servicio</h5>
					El Sitio proporciona un servicio de búsqueda y comparación de productos e inmuebles. Las publicaciones disponibles en el Sitio se obtienen de plataformas de terceros (en adelante, "Plataformas de Origen"). El Sitio no es responsable de la exactitud, integridad o disponibilidad de los datos obtenidos de estas Plataformas de Origen.<br />
					<br />
					<h5>3. Uso del Sitio</h5>
					Propósito Personal: El Sitio está destinado exclusivamente para uso personal y no comercial. Usted se compromete a no utilizar el Sitio con fines comerciales sin autorización previa por escrito de Choizr.com.<br />
					<br />
					Prohibiciones: Está prohibido copiar, reproducir, modificar, distribuir o crear trabajos derivados del contenido del Sitio, salvo que se permita expresamente en estos Términos o por la ley aplicable.<br />
					<br />
					<h5>4. Enlaces y Contenidos de Terceros</h5>
					El Sitio puede contener enlaces a sitios web de terceros o contenidos provenientes de las Plataformas de Origen. Choizr.com no respalda ni asume responsabilidad alguna por el contenido, productos o servicios disponibles en dichos sitios o plataformas. Usted accede a estos contenidos bajo su propio riesgo.<br />
					<br />
					<h5>5. Exclusión de Garantías</h5>
					El Sitio se proporciona "tal cual" y "según disponibilidad". Choizr.com no ofrece garantías de ningún tipo, ya sean explícitas o implícitas, incluyendo, pero no limitándose a, garantías de comerciabilidad, idoneidad para un propósito particular y no infracción. No garantizamos que el Sitio sea seguro, libre de errores o que esté disponible en todo momento.<br />
					<br />
					<h5>6. Limitación de Responsabilidad</h5>
					En la medida máxima permitida por la ley aplicable, Choizr.com no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, incluyendo, pero no limitándose a, pérdida de beneficios, pérdida de datos, o cualquier otro tipo de daño que resulte del uso o la incapacidad de usar el Sitio.<br />
					<br />
					<h5>7. Propiedad Intelectual</h5>
					Todos los derechos de propiedad intelectual relacionados con el Sitio y su contenido, incluyendo, sin limitación, textos, gráficos, logotipos, íconos y software, son propiedad de Choizr.com o de sus licenciantes. Usted no adquiere ningún derecho sobre dichos contenidos excepto en la medida en que se le permita utilizar el Sitio de acuerdo con estos Términos.<br />
					<br />
					<h5>8. Modificaciones a los Términos</h5>
					Choizr.com se reserva el derecho de modificar estos Términos en cualquier momento. Las modificaciones serán efectivas al momento de su publicación en el Sitio. Es su responsabilidad revisar los Términos periódicamente para estar al tanto de cualquier cambio. El uso continuado del Sitio después de la publicación de modificaciones constituye su aceptación de los Términos modificados.<br />
					<br />
					<h5>9. Ley Aplicable y Jurisdicción</h5>
					Estos Términos se regirán e interpretarán de acuerdo con las leyes de Argentina. Cualquier disputa relacionada con estos Términos se resolverá exclusivamente en los tribunales competentes de Argentina.<br />
					<br />
					<h5>10. Contacto</h5>
					Si tiene alguna pregunta o comentario sobre estos Términos, puede ponerse en contacto con nosotros a través de {{ $env.CONTACT_EMAIL }}.
				</p>
			</div>
		</form>
	</LayoutClean>
</template>