<template>
	<div class="text-md">
		<span class="badge bg-success me-2" v-if="tags.includes('top')"><i class="bi bi-arrow-up-circle-fill ms-n1 me-1"></i>Top</span>
		<span class="badge bg-warning me-2" v-if="tags.includes('visited')"><i class="bi bi-door-closed ms-n1 me-1"></i>Visitada</span>
		<span class="badge bg-danger me-2" v-if="tags.includes('discarded')"><i class="bi bi-hand-thumbs-down ms-n1 me-1"></i>Descartada</span>
	</div>
</template>

<script>
	export default {
		props: {
			tags: Array
		}
	}
</script>