<template>
	<header class="bg-surface pt-4">
		<div class="container-md">
			<div class="row g-2 align-items-center">
				<div class="col-12 lh-1" :class="{ 'col-lg-8': !!$slots.actions }" v-if="$slots.title">
					<slot name="title" />
				</div>
				<div class="col-12 text-lg-end" :class="{ 'col-lg-4': !!$slots.title }" v-if="$slots.actions">
					<slot name="actions" />
				</div>
			</div>
		</div>
	</header>
</template>