<template>
	<div class="dropdown">
		<a href="javascript:void(0);" class="link-dark hstack gap-2 align-items-center py-1 ps-3 pe-2 border rounded-pill shadow-2-hover bg-white" data-bs-toggle="dropdown" aria-expanded="false">
			<i class="bi bi-list text-lg"></i>
			<UserAvatarRounded :user="user" size="xs" />
		</a>
		<div class="dropdown-menu dropdown-menu-end px-0 mt-2 rounded-4">
			<div class="dropdown-header vstack lh-xs">
				<span class="text-heading text-md fw-bold">{{ user.name }}</span>
				<span class="text-muted fw-semibold">{{ user.email }}</span>
				<router-link :to="{ name: 'user.home', params: { slug: user.slug }}" class="text-primary fw-semibold" v-if="user.slug">Ver sitio</router-link>
			</div>
			<div class="dropdown-divider"></div>
			<router-link :to="{ name: 'account.search.index' }" class="dropdown-item rounded-0">Búsquedas</router-link>
			<router-link :to="{ name: 'account.group.index' }" class="dropdown-item rounded-0">Grupos</router-link>
			<router-link :to="{ name: 'account.publication.index' }" class="dropdown-item rounded-0">Publicaciones</router-link>
			<template v-if="(user.permissions ?? []).length > 0">
				<div class="dropdown-divider"></div>
				<div class="dropdown-header fw-bold">Administración</div>
				<router-link :to="{ name: 'admin.user.index' }" class="dropdown-item rounded-0" v-if="(user.permissions ?? []).includes('users')">Usuarios</router-link>
				<router-link :to="{ name: 'admin.search.index' }" class="dropdown-item rounded-0" v-if="(user.permissions ?? []).includes('searches')">Búsquedas</router-link>
			</template>
			<div class="dropdown-divider"></div>
			<router-link :to="{ name: 'account.setting.index' }" @click.native="setNext" class="dropdown-item rounded-0">Mi perfil</router-link>
			<router-link :to="{ name: 'auth.logout' }" @click.native="setNext" class="dropdown-item rounded-0">Cerrar sesión</router-link>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';
	import store from '@/store';

	export default {
		props: {
			user: Object,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup() {
			const route = useRoute();
			const setNext = () => {
				store.dispatch('redirect/setNext', route.fullPath);
			}

			return { setNext };
		}
	}
</script>