<template>
	<LayoutModalStandard ref="modal" :title="title" :id="id">
		<template #body>
			<div class="my-auto text-center">
				<p class="text-md text-break lh-sm" v-html="description"></p>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn rounded-pill w-100" :class="submitClass" @click="success">{{ submitText }}</button>
		</template>
	</LayoutModalStandard>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';

	export default {
		emits: ['success'],
		props: {
			title: String,
			description: String,
			target: Object,
			submitText: {
				type: String,
				default: 'Continuar'
			},
			submitClass: {
				type: String,
				default: 'btn-primary'
			},
			id: {
				type: String,
				default: 'common-modal-action'
			}
		},
		setup(props, { emit }) {
			const modal = ref(null);

			const description = computed(() => {
				if(props?.description) return props.description;
				else if(props?.target?.description) return props.target.description;
				else return null;
			});

			const success = () => {
				emit('success', props.target);
				modal.value.modal.hide();
			}

			const show = () => modal.value.modal.show();

			return { modal, description, success, show };
		}
	}
</script>