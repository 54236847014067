<template>
	<div class="avatar media-wrapper rounded-circle square bg-light" :class="`avatar-${size}`">
		<img :src="avatar_url" class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsAvatar from '@/assets/img/icons/avatar.jpg';

	export default {
		props: {
			user: Object,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup(props) {
			const avatar_url = computed(() => {
				if(props.user?.avatar_url) return props.user.avatar_url;
				else return assetsImgIconsAvatar;
			});

			return { avatar_url };
		}
	}
</script>