import axios from 'axios';
import store from '@/store';

let url = new URL(window.location.origin);
	url.pathname = '/api';
	url.port = '';
const baseAPI = url.toString();

const instance = axios.create({
	withCredentials: true,
	baseURL: baseAPI,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Cache-Control': 'no-cache'
	}
});

instance.interceptors.request.use((config) => {
	var token = store.getters['auth/token'];
	if(token !== null) config.headers['Authorization'] = `${token.token_type} ${token.access_token}`;

	return config;
});

export default instance;
