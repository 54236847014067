export default {
	namespaced: true,
	state: {
		info: null
	},
	getters: {
		info(state) {
			return state.info;
		}
	},
	actions: {
		setInfo({ commit }, data) {
			commit('setInfo', data);
		},
		empty({ commit }) {
			commit('setInfo', null);
		}
	},
	mutations: {
		setInfo(state, info) {
			state.info = info;
		}
	}
}