<template>
	<div class="avatar bg-white rounded-circle" :class="classes">
		<img :src="assetsImgLogoSmall" class="object-cover" alt="Avatar">
	</div>
</template>

<script>
	import assetsImgLogoSmall from '@/assets/img/logo/small.png';

	export default {
		props: {
			imageClass: String,
			size: {
				type: String,
				default: 'md'
			}
		},
		setup(props) {
			const classes = [
				`avatar-${props.size}`,
				props.imageClass
			];

			return { classes, assetsImgLogoSmall };
		}
	}
</script>