<template>
	<div class="vstack gap-4 text-center">
		<AccountGroupCommonImage :src="group.logo_url" :size="imageSize" class="mx-auto" v-if="group?.logo_url" />
		<div class="vstack gap-1 lh-xs">
			<div class="text-break" :class="nameClass">{{ group.name }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			group: Object,
			nameClass: {
				type: String,
				default: 'h2 px-5'
			},
			imageSize: {
				type: String,
				default: 'xl'
			}
		}
	}
</script>