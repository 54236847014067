<template>
	<LayoutClean>
		<form class="container-md mh-100 py-10" @submit.prevent="submit" autocomplete="off">
			<div class="pb-4 vstack gap-4">
				<div>
					<router-link :to="{ name: 'home' }" class="d-inline-block">
						<img src="@/assets/img/logo/complete.png" class="h-rem-12" alt="logo">
					</router-link>
				</div>
				<p class="lh-xs">
					<h3>Políticas de privacidad</h3>
					<br />
					Última actualización: 06/08/2024<br />
					<br />
					<h5>1. Introducción</h5>
					En Choizr.com (en adelante, "el Sitio"), valoramos su privacidad y nos comprometemos a proteger la información personal que nos proporciona. Esta Política de Privacidad describe cómo recopilamos, utilizamos, compartimos y protegemos su información personal. Al utilizar el Sitio, usted acepta las prácticas descritas en esta política.<br />
					<br />
					<h5>2. Información que Recopilamos</h5>
					<h6>2.1 Información que Usted Proporciona</h6>
					Podemos recopilar información personal que usted nos proporciona directamente, como su nombre, dirección de correo electrónico y otros datos de contacto cuando se registra en el Sitio, se suscribe a nuestro boletín informativo o se comunica con nosotros.<br />
					<br />
					<h6>2.2 Información Recopilada Automáticamente</h6>
					Al utilizar el Sitio, podemos recopilar automáticamente cierta información sobre su dispositivo y uso del Sitio, como su dirección IP, tipo de navegador, páginas visitadas y la fecha y hora de su visita. Utilizamos cookies y tecnologías similares para recopilar esta información.<br />
					<br />
					<h6>2.3 Información de Terceros</h6>
					Podemos recibir información sobre usted de otras fuentes, como plataformas de terceros donde se originan las publicaciones que mostramos en el Sitio.<br />
					<br />
					<h5>3. Uso de la Información</h5>
					Utilizamos la información que recopilamos para:<br />
					<br />
					<ul>
						<li>Proporcionar y mejorar nuestros servicios.</li>
						<li>Personalizar su experiencia en el Sitio.</li>
						<li>Comunicarnos con usted, responder a sus consultas y enviarle actualizaciones.</li>
						<li>Analizar el uso del Sitio y mejorar su funcionamiento.</li>
						<li>Cumplir con nuestras obligaciones legales y proteger nuestros derechos.</li>
					</ul>
					<h5>4. Compartir Información</h5>
					No vendemos, alquilamos ni compartimos su información personal con terceros para sus fines de marketing sin su consentimiento. Podemos compartir su información con:<br />
					<br />
					<ul>
						<li><b>Proveedores de Servicios:</b> Proveedores que nos ayudan a operar el Sitio y prestar nuestros servicios.</li>
						<li><b>Entidades Legales:</b> Cuando sea necesario para cumplir con la ley, responder a procesos legales o proteger nuestros derechos.</li>
						<li><b>Terceros en Caso de Transacción Corporativa:</b> En caso de fusión, venta de activos o reestructuración corporativa, su información puede ser transferida como parte de la transacción.</li>
					</ul>
					<h5>5. Seguridad de la Información</h5>
					Implementamos medidas de seguridad adecuadas para proteger su información personal contra el acceso no autorizado, alteración, divulgación o destrucción. Sin embargo, ningún sistema de seguridad es completamente seguro, por lo que no podemos garantizar la seguridad absoluta de su información.<br />
					<br />
					<h5>6. Sus Derechos</h5>
					Dependiendo de su jurisdicción, usted puede tener ciertos derechos con respecto a su información personal, como el derecho a acceder, corregir, eliminar o restringir el uso de su información. Para ejercer estos derechos, póngase en contacto con nosotros a través de los detalles proporcionados en la sección 9.<br />
					<br />
					<h5>7. Cookies y Tecnologías Similares</h5>
					Utilizamos cookies y tecnologías similares para recopilar información y mejorar su experiencia en el Sitio. Puede configurar su navegador para rechazar cookies, pero esto puede limitar su uso de ciertas funcionalidades del Sitio.<br />
					<br />
					<h5>8. Enlaces a Otros Sitios</h5>
					El Sitio puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad de estos sitios y le recomendamos que revise sus políticas de privacidad antes de proporcionarles cualquier información personal.<br />
					<br />
					<h5>9. Contacto</h5>
					Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad o sobre nuestras prácticas de privacidad, puede contactarnos a través de {{ $env.CONTACT_EMAIL }}.<br />
					<br />
					<h5>10. Cambios en la Política de Privacidad</h5>
					Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Cualquier cambio será efectivo al momento de su publicación en el Sitio. Le recomendamos que revise esta política periódicamente para estar al tanto de cualquier actualización.
				</p>
			</div>
		</form>
	</LayoutClean>
</template>