<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="text-white-space" v-html="modelValue"></div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, defineProps, defineEmits } from 'vue';

	export default {
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;

			return { inputId };
		}
	}
</script>