<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Recibí notificaciones" id="app-permission-notification">
		<template #body>
			<div class="vstack">
				<h3 class="fw-bolder text-dark">¿Querés recibir notificaciones?</h3>
				<p class="text-md text-muted lh-sm">De esta manera podrás recibir notificaciones importantes de <b>{{ viteAppName }}</b>.</p>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill" @click="dismiss">Ahora no</button>
			<button type="button" class="btn btn-primary rounded-pill" @click="accept">Aceptar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue';
	import store from '@/store';
	import { useToast } from 'vue-toastification';
	import { VueCookieNext } from 'vue-cookie-next';
	import * as PusherPushNotifications from '@pusher/push-notifications-web';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		setup() {
			const $env = inject('$env');
			const viteAppName = import.meta.env.VITE_APP_NAME;
			const toast = useToast();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const token = computed(() => store.getters['auth/token']);
			const user = computed(() => store.getters['auth/user']);
			const beamsClient = ref(null);
			const timeout = ref(null);

			const offcanvas = ref(null);
			const permissionNotificationsShow = () => offcanvas.value.offcanvas.show();
			const permissionNotificationsHide = () => offcanvas.value.offcanvas.hide();

			const dismiss = () => {
				VueCookieNext.setCookie('notifications-allowed', null, { expire: '10d' });
				permissionNotificationsHide();
			}

			const accept = () => {
				let url = new URL(window.location.origin);
					url.pathname = '/api';
					url.port = '';

				const tokenProvider = new PusherPushNotifications.TokenProvider({
					url: `${url.toString()}/auth/user/beams/auth`,
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Cache-Control': 'no-cache',
						'Authorization': `${token.value.token_type} ${token.value.access_token}`
					},
				});

				beamsClient.value.start()
					.then(() => beamsClient.value.setUserId(String(user.value.id), tokenProvider))
					//.then(() => permissionNotificationsHide())
					.catch(console.error);
			}

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			const previousShow = () => {
				if(VueCookieNext.isCookieAvailable('notifications-allowed')) return false;
				else if(!hasUser.value || document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 60000);
				else permissionNotificationsShow();
			}

			const clientCheck = () => {
				if(beamsClient.value) beamsClient.value.getRegistrationState().then((state) => {
					let states = PusherPushNotifications.RegistrationState;

					console.log('beams state', state);

					switch(state) {
						case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS:
							if(!hasUser.value) beamsClient.value.stop();
							break;
						case states.PERMISSION_DENIED:
							toast.error('Las notificaciones están desactivadas en éste dispositivo.');
							break;
						case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
						case states.PERMISSION_PROMPT_REQUIRED:
							if(hasUser.value) accept();
							//timeout.value = setTimeout(previousShow, 5000);
							break;
					}
				});
			}

			watch(hasUser, clientCheck);

			onMounted(() => {
				window.navigator.serviceWorker?.ready.then((serviceWorkerRegistration) => {
					beamsClient.value = new PusherPushNotifications.Client({ instanceId: $env.PUSHER_BEAMS_INSTANCE_ID, serviceWorkerRegistration: serviceWorkerRegistration });
					clientCheck();
				});
			});

			return { viteAppName, offcanvas, dismiss, accept };
		}
	}
</script>