<template>
	<div class="text-truncate" v-if="info">{{ info }}</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			users: Array
		},
		setup(props) {
			const info = computed(() => {
				let creator = _.find(props.users, { pivot: { creator: true }});

				if(creator) return creator.name;
				else return null;
			});

			return { info };
		}
	}
</script>