import Repository from '../Repository';
import { useToast } from 'vue-toastification';
import store from '@/store';

const resource = '/account/group';
const toast = useToast();

export default {
	index(params) {
		return Repository.get(`${resource}`, { params: params });
	},
	store(payload) {
		return Repository.post(`${resource}`, payload).then((response) => {
			store.dispatch('auth/me');
			toast.success(`Grupo cargado correctamente.`);
			return response;
		});
	},
	show(id) {
		return Repository.get(`${resource}/${id}`);
	},
	update(id, payload) {
		return Repository.put(`${resource}/${id}`, payload).then((response) => {
			store.dispatch('auth/me');
			toast.success(`Grupo actualizado correctamente.`);
			return response;
		});
	},
	leave(id) {
		return Repository.delete(`${resource}/${id}`).then((response) => {
			store.dispatch('auth/me');
			toast.success(`Saliste del grupo.`);
			return response;
		});
	}
}