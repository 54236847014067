<template>
	<transition name="fade-slow" mode="out-in">
		<img :src="url" class="img-fluid border border-2 border-dark rounded-4 my-1" v-if="ready">
	</transition>
</template>

<script>
	import { ref, onMounted } from 'vue';

	export default {
		props: {
			url: String
		},
		setup(props) {
			const ready = ref(false);

			onMounted(() => {
				var loadImage = new Image();
				loadImage.src = props.url;
				loadImage.onload = function() { ready.value = true; };
			});

			return { ready };
		}
	}
</script>