<template>
	<LayoutOffcanvasStandard ref="offcanvas" :title="title" :subtitle="subtitle" id="common-modal-image-processor">
		<template #body>
			<div class="img-container" :class="{ 'cropper-rounded': rounded }">
				<img id="img-cropper" :src="proccessImageData?.file" class="w-100 invisible">
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn rounded-pill w-100" :class="submitClass" @click="success">{{ submitText }}</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import Cropper from 'cropperjs';
	import 'cropperjs/dist/cropper.css';

	export default {
		emits: ['success'],
		props: {
			title: String,
			subtitle: String,
			submitText: {
				type: String,
				default: 'Continuar'
			},
			submitClass: {
				type: String,
				default: 'btn-primary'
			},
			rounded: Boolean
		},
		setup(props, { emit }) {
			const offcanvas = ref(null);
			const cropper = ref(null);
			const proccessImageData = ref(null);

			const success = async () => {
				let data = await cropper.value.getCroppedCanvas().toDataURL('image/png');
				emit('success', data);

				offcanvas.value.offcanvas.hide();
			}

			const show = async (data) => {
				proccessImageData.value = data;
				await offcanvas.value.offcanvas.show();
			}

			onMounted(async () => {
				document.getElementById('common-modal-image-processor').addEventListener('shown.bs.offcanvas', () => {
					cropper.value = new Cropper(document.getElementById('img-cropper'), {
						aspectRatio: proccessImageData.value.aspect,
						viewMode: 1,
						dragMode: 'move',
						background: false,
						restore: false,
						guides: false,
						highlight: true,
						cropBoxMovable: false,
						cropBoxResizable: false,
						toggleDragModeOnDblclick: false,
						autoCropArea: 1,
						modal: true,
						center: true
					});
				});

				document.getElementById('common-modal-image-processor').addEventListener('hidden.bs.offcanvas', () => {
					cropper.value.destroy();
				});
			});

			return { proccessImageData, offcanvas, success, show };
		}
	}
</script>

<style scoped>
	.img-container {
		max-height: 400px;
		width: auto;
		overflow: hidden;
		border-radius: 1rem !important;
	}

	#img-cropper {
		max-height: 400px;
		width: auto;
	}
</style>