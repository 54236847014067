<template>
	<teleport to="body">
		<div :id="id" class="modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
				<div class="modal-content rounded-md-4 border border-2 border-dark overflow-hidden">
					<div class="modal-header px-5 pb-2">
						<div class="vstack" v-if="title || subtitle">
							<div class="h3 modal-title" v-if="title">{{ title }}</div>
							<div class="text-muted lh-xs" v-if="subtitle">{{ subtitle }}</div>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body px-5 vstack gap-5">
						<slot name="body" />
					</div>
					<div class="modal-footer px-5 pt-2" v-if="$slots.footer">
						<div class="hstack gap-3 justify-content-end m-0 w-100">
							<slot name="footer" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		props: {
			id: {
				type: String,
				default: 'layout-modal-standard'
			},
			title: String,
			subtitle: String
		},
		setup(props) {
			const modal = ref(null);
			onMounted(() => {
				modal.value = new Modal(document.getElementById(props.id));
			});

			return { modal };
		}
	}
</script>