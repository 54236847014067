<template>
	<tr>
		<td class="p-0" width="50">
			<CommonImageAvatar :src="imageUrl" size="md" />
		</td>
		<td class="position-relative lh-1">
			<div class="vstack gap-1">
				<router-link :to="{ name: 'account.group.show', params: { id: group.id }}" class="link-dark text-md fw-bold stretched-link">{{ group.name }}</router-link>
				<div class="text-sm text-muted hstack gap-1">
					<div class="text-dark fw-bold">{{ (group.pivot.admin) ? 'Administrador' : 'Miembro' }}</div>
					<div>·</div>
					<div>{{ (group.users_count == 1) ? `1 miembro` : `${group.users_count} miembros` }}</div>
				</div>
			</div>
		</td>
		<td class="text-end py-0">
			<router-link :to="{ name: 'group.search.index', params: { id: group.id }}" class="btn btn-sm btn-neutral rounded-pill">Buscar</router-link>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import router from '@/router';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		props: {
			group: Object
		},
		setup(props, { emit }) {
			const imageUrl = computed(() => {
				if(props.group.logo_url) return props.group.logo_url;
				else return assetsImgIconsNoImage;
			});

			return { imageUrl };
		}
	}
</script>