import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/account/group';
const toast = useToast();

export default {
	index(group_id, params) {
		return Repository.get(`${resource}/${group_id}/user`, { params: params });
	},
	invite(group_id, payload) {
		return Repository.post(`${resource}/${group_id}/user/invite`, payload);
	},
	update(group_id, id, payload) {
		return Repository.put(`${resource}/${group_id}/user/${id}`, payload).then((response) => {
			toast.success(`Miembro actualizado correctamente.`);
			return response;
		});
	},
	destroy(group_id, id) {
		return Repository.delete(`${resource}/${group_id}/user/${id}`).then((response) => {
			toast.success(`Miembro eliminado correctamente.`);
			return response;
		});
	}
}