<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="input-group">
			<span class="input-group-text" id="basic-addon1" v-if="preppend">{{ preppend }}</span>
			<input type="text"
					:id="inputId"
					:value="modelValue"
					@input="emitInput"
					class="form-control"
					:class="{'is-invalid': validation?.$error}"
					:placeholder="placeholder"
					v-focus="focus"
					autocomplete="off">
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			placeholder: String,
			preppend: String,
			validation: Object,
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);

			const generateSlug = (text) => {
				return  text.toLowerCase()            // Convertir a minúsculas
							.replace(/\s+/g, '-')     // Reemplazar espacios por guiones
							.replace(/[^a-z0-9-]/g, '') // Eliminar caracteres no permitidos
							.replace(/-+/g, '-')      // Remover múltiples guiones
							.replace(/^-|-$/g, '');   // Eliminar guiones al inicio o final
			};

			const emitInput = (event) => {
				inputValue.value = generateSlug(event.target.value);
				emit('update:modelValue', inputValue.value);
			};

			return { inputId, inputValue, emitInput };
		}
	}
</script>