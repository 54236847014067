<template>
	<div class="rounded-pill px-3 ps-2 py-1 lh-1 hstack gap-1 border border-2 shadow fw-bold bg-white" :class="`border-${info.style.color} text-${info.style.color}`" v-if="info">
		<i class="bi" :class="`${iconClass} bi-${info.style.icon}`" v-if="info.style.icon"></i>{{ info.title }}
	</div>
</template>

<script>
	import { computed, inject } from 'vue';

	export default {
		props: {
			priority: Number,
			iconClass: String
		},
		setup(props) {
			const $env = inject('$env');
			const info = computed(() => (props.priority in $env.MODEL.publication.priorities) ? $env.MODEL.publication.priorities[props.priority] : null);

			return { info };
		}
	}
</script>