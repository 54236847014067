import Repository from './Repository';

const resource = '/invitation';

export default {
	show(uuid) {
		return Repository.get(`${resource}/${uuid}`);
	},
	accept(uuid) {
		return Repository.put(`${resource}/${uuid}/accept`);
	},
	reject(uuid) {
		return Repository.put(`${resource}/${uuid}/reject`);
	}
}