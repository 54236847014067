<template>
	<div class="vstack gap-2 lh-xs py-1">
		<div>
			<div class="fw-bold">Super Like!</div>
			<div class="text-sm">Enviaremos tus datos de contacto a los administradores de la publicación para que puedan contactarte.</div>
		</div>
		<div class="hstack gap-4">
			<a href="javascript:void(0);" class="text-white text-sm" @click="undo"><i class="bi bi-x-lg pe-2"></i>Cancelar super like</a>
			<a href="javascript:void(0);" class="text-white text-sm" @click="setting"><i class="bi bi-gear pe-2"></i>Mis datos de contacto</a>
		</div>
	</div>
</template>

<script>
	import store from '@/store';
	import router from '@/router';

	export default {
		events: ['undo'],
		props: {
			id: Number
		},
		setup(props, { emit }) {
			const undo = () => emit('undo', props.id);
			const setting = () => router.push({ name: 'account.setting.index' });

			return { undo, setting };
		}
	}
</script>