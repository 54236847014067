<template>
	<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
		<div class="text-dark lh-sm">
			<template v-if="sent">Ingresá el código que te enviamos a</template>
			<template v-else>Enviando código a</template>
			<b class="d-block">{{ user?.email }}</b>
		</div>
		<CommonFormInputNumber v-model="form.code" title="Código" placeholder="Ingrese el código" :focus="true" :validation="validation.code" />
	</form>
</template>

<script>
	import { ref, reactive, computed, onBeforeMount } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AuthRepository from '@/repositories/AuthRepository';

	export default {
		emits: ['updateStep', 'hide'],
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();

			const user = computed(() => store.getters['auth/user']);
			const sent = ref(false);
			const form = reactive({
				code: null
			});

			const validation = useVuelidate({
				code: {
					required
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await AuthRepository.verifyCheck(form).then(async (response) => {
					await store.dispatch('auth/me');
					emit('hide');
				}).catch((e) => catchError(e, true));
				submitting.value = false;
			}

			onBeforeMount(() => {
				AuthRepository.verifySend().then((response) => {
					sent.value = true;
				}).catch((e) => catchError(e, true));
			});

			return { validation, user, form, submitting, submit, sent };
		}
	}
</script>