<template>
	<nav class="navbar navbar-expand-lg navbar-light px-0">
		<div class="container-md d-flex align-items-center justify-content-between">
			<!-- Logo -->
			<router-link :to="{ name: 'home' }" v-if="route.name == 'home'">
				<img src="@/assets/img/logo/complete.png" class="h-rem-10">
			</router-link>
			<a href="javascript:void(0);" class="btn btn-sm py-2 border shadow-2-hover rounded-pill" @click="redirectBack" v-else>
				<i class="bi bi-chevron-left pe-2"></i>Volver
			</a>
			<div class="hstack gap-3">
				<a href="javascript:void(0);" class="btn btn-sm btn-outline-primary py-2 shadow-2-hover rounded-pill" @click="redirectSearch" v-if="searchShow"><i class="bi bi-search pe-2"></i>Nueva búsqueda</a>
				<UserCardMenu :user="user" v-if="hasUser" />
				<UserCardMenuEmpty v-else />
			</div>
		</div>
	</nav>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';

	export default {
		setup() {
			const { redirectBack, redirectSearch } = composableRedirect();
			const route = useRoute();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const searchShow = computed(() => (!route.meta?.searchForm));

			return { redirectBack, redirectSearch, route, hasUser, user, searchShow };
		}
	}
</script>

<style scoped>
	.navbar-nav > .nav-item > .nav-link, .navbar-nav > .nav-link {
		background-color: inherit;
	}

	.navbar-nav > .nav-item > .nav-link:hover, .navbar-nav > .nav-item > .nav-link:focus, .navbar-nav > .nav-link:hover, .navbar-nav > .nav-link:focus {
		background-color: inherit;
	}

	.item-search {
		min-width: 240px;
	}
</style>