export default {
	namespaced: true,
	state: {
		next: null,
		search: null
	},
	getters: {
		next(state) {
			return state.next;
		},
		search(state) {
			return state.search;
		}
	},
	actions: {
		setNext({ commit }, next) {
			commit('setNext', next);
		},
		setSearch({ commit }, search) {
			commit('setSearch', search);
		}
	},
	mutations: {
		setNext(state, next) {
			state.next = next;
		},
		setSearch(state, search) {
			state.search = search;
		}
	}
}