<template>
	<LayoutClean class="vstack justify-content-center">
		<div class="container-sm mh-100 py-10">
			<div class="vstack gap-4 text-center">
				<div>
					<router-link :to="{ name: 'home' }" class="d-inline-block">
						<img src="@/assets/img/logo/complete.png" class="h-rem-12" alt="logo">
					</router-link>
				</div>
				<div class="ls-tight lh-sm text-md text-center px-5 px-md-10">Página no encontrada :(</div>
				<div>
					<router-link :to="{ name: 'home' }" class="btn btn-primary rounded-pill">Ir al inicio</router-link>
				</div>
			</div>
		</div>
	</LayoutClean>
</template>