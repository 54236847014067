import { createApp } from 'vue';
import App from './App.vue';

//Styles
import '@/assets/css/customization.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp({
	extends: App,
	methods: {
		checkUser() {
			if(store.getters['auth/user']) store.dispatch('auth/me');
		},
		documentHeight() {
			document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
		}
	},
	beforeMount() {
		window.addEventListener('resize', this.documentHeight);
		this.documentHeight();

		setInterval(this.checkUser, (5 * 60000));
		this.checkUser();
	}
});

//Vue Store
import store from '@/store';
app.use(store);

//Configuration
import { loadConfiguration } from '@/plugins/Configuration';
await loadConfiguration(app);

//Vue Router
import router from '@/router';
app.use(router);

//Traducciones
import i18n from '@/helpers/i18n/init.js';
app.use(i18n);

//Moment Js
import moment from 'moment';
import i18n_es from '@/helpers/i18n/translations/es.json';
moment.locale('es', i18n_es.moment);

//Vue Number Format
import VueNumberFormat from 'vue-number-format';
app.use(VueNumberFormat, { prefix: '', thounsand: '.', decimal: ',' });

//Vue Google Login
import vue3GoogleLogin from 'vue3-google-login';
app.use(vue3GoogleLogin, { clientId: app.config.globalProperties.$env.GOOGLE_CLIENT_ID });

//Vue Toast
import Toast from 'vue-toastification';
app.use(Toast, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 3000, closeOnClick: true, pauseOnFocusLoss: true, pauseOnHover: true, draggable: true, showCloseButtonOnHover: false, closeButton: false, icon: false });

//Vue Focus directive
app.directive('focus', { mounted(el, active) { if(active.value == undefined || active.value == true) el.focus(); } });

app.mount('body');
