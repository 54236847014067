<template>
	<router-view v-slot="{ Component }">
		<transition name="fade-fast" mode="out-in">
			<LayoutLoading text="Cargando grupo" v-if="!loaded" />
			<component :is="Component" :group="group" v-else />
		</transition>
	</router-view>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import composableForm from '@/composables/form';
	import GroupRepository from '@/repositories/GroupRepository';

	export default {
		setup() {
			const { catchError } = composableForm();
			const route = useRoute();
			const group = ref(null);
			const loaded = ref(false);

			onMounted(async () => {
				store.dispatch('redirect/setSearch', { name: 'group.search.index', params: { id: route.params.id }});

				await GroupRepository.show(route.params.id).then((response) => {
					group.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					return router.push({ name: 'home' });
				});

				loaded.value = true;
			});

			return { group, loaded };
		}
	}
</script>