<template>
	<LayoutModalStandard ref="modal" :title="publication?.title" :subtitle="publication?.publicable?.name" id="publication-modal-show">
		<template #body v-if="publication">
			<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
				<div class="d-flex gap-2 scrollable-x mx-n5 px-5">
					<!--<div v-for="media in publication?.medias" v-bind:key="media.id" class="avatar avatar-xl avatar-background border border-2 border-dark rounded-3">-->
					<div v-for="media in publication?.medias" v-bind:key="media.id" class="position-relative avatar avatar-xl rounded-4 border border-2 border-dark">
						<img :src="media.file_url" class="object-cover position-absolute top-50 start-50 translate-middle rounded-0" />
					</div>
				</div>
				<PublicationPartBadge :publication="publication" :scrollable="false" color="dark" />
				<LayoutFormInputGeneral :title="publication?.address?.title" v-if="publication.address">
					<CommonMapMap :center="{ lat: publication.address.geolocation.coordinates[0], lng: publication.address.geolocation.coordinates[1] }">
						<CommonMapMarker :position="{ lat: publication?.address.geolocation.coordinates[0], lng: publication?.address.geolocation.coordinates[1] }" />
					</CommonMapMap>
				</LayoutFormInputGeneral>
				<PublicationPartPriorityComplete :priority="publication.priority" v-if="publication.priority" />
				<PublicationPartUsers :users="publication.users" />
				<PublicationPartStock :stock="publication.extras.stock" v-if="publication.extras.stock" />
				<PublicationPartExtras :extras="publication.extras" v-if="publication.extras" />
				<LayoutFormInputGeneral title="Descripción" v-if="publication.description">
					<div class="text-md lh-xs" v-html="publication.description"></div>
				</LayoutFormInputGeneral>
				<PublicationPartDecisions :users="publication.users_decisions" v-if="publication.users_decisions.length > 0" />
				<CommonFormInputTextarea v-model="form.comment" title="Comentarios" placeholder="Escribe aquí los comentarios que creas convenientes..." :rows="3" v-if="canEdit" />
			</form>
		</template>
		<template #footer v-if="publication">
			<template v-if="canEdit">
				<button type="button" class="btn btn-outline-danger rounded-pill me-auto" :disabled="submitting" @click="decision(false);" v-if="publication.pivot.like">Ya no me gusta</button>
				<button type="button" class="btn btn-outline-success rounded-pill me-auto" :disabled="submitting" @click="decision(true);" v-else>Me gusta</button>
				<button type="button" class="btn btn-primary rounded-pill" :disabled="submitting" @click="submit">Guardar</button>
			</template>
			<template v-else>
				<button type="button" class="btn btn-neutral rounded-pill" data-bs-dismiss="modal">Cerrar</button>
			</template>
		</template>
	</LayoutModalStandard>
</template>

<script>
	import { ref, reactive, computed, watch } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		emits: ['success'],
		props: {
			search: Object,
			filters: Object,
			publication: Object,
			isActive: Boolean
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const canEdit = computed(() => (props.filters.user_id == user.value.id));
			const modal = ref(null);

			const form = reactive({
				comment: null
			});

			watch(() => props.publication, (newValue) => {
				form.comment = newValue.pivot.comment;
			});

			const submit = async () => {
				if(!canEdit.value) return;
				if(submitting.value) return;
				if(form.comment == props.publication.pivot.comment) return modal.value.modal.hide();
				submitting.value = true;

				await SearchRepository.decision(props.search.uuid, { publication_id: props.publication.id, comment: form.comment }).then((response) => {
					emit('success');
					modal.value.modal.hide();
				}).catch(catchError);

				submitting.value = false;
			}

			const decision = async (like) => {
				if(submitting.value) return;
				submitting.value = true;

				await SearchRepository.decision(props.search.uuid, { publication_id: props.publication.id, like: like }).then((response) => {
					emit('success');
					modal.value.modal.hide();
				}).catch(catchError);

				submitting.value = false;
			}

			const show = () => modal.value.modal.show();

			return { canEdit, modal, form, submit, submitting, decision, show };
		}
	}
</script>