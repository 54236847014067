<template>
	<LayoutOffcanvasStandard ref="offcanvas" :title="user?.name" id="account-group-user-modal-option">
		<template #body>
			<div class="list-group list-group-flush gap-5" v-if="user">
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="user.pivot.admin">
					<div>
						<a href="javascript:void(0)" class="d-block h5 font-semibold stretched-link" @click="update({ admin: false });">Descartar como admininistrador</a>
						<p class="text-sm lh-sm">No podrá editar el grupo o agregar participantes</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-else>
					<div>
						<a href="javascript:void(0)" class="d-block h5 font-semibold stretched-link" @click="update({ admin: true });">Designar como admininistrador</a>
						<p class="text-sm lh-sm">Podrá editar el grupo, agregar participantes y más</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0">
					<div>
						<a href="javascript:void(0)" class="d-block h5 font-semibold stretched-link" @click="remove">Eliminar usuario del grupo</a>
						<p class="text-sm lh-sm">Ya no tendrá acceso al grupo</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>

	<CommonModalAction ref="modalRemove" id="common-modal-action-user" title="Eliminar usuario" description="¿Desea eliminar este usuario? Al eliminarlo, ya no tendrá acceso al grupo" submitText="Eliminar" submitClass="btn-danger" :target="user" @success="destroy" />
</template>

<script>
	import { ref } from 'vue';
	import composableForm from '@/composables/form';
	import AccountGroupUserRepository from '@/repositories/Account/GroupUserRepository';

	export default {
		emits: ['reload'],
		props: {
			group: Object,
			user: Object
		},
		setup(props, { emit }) {
			const { catchError } = composableForm();
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const modalRemove = ref(null);
			const remove = () => modalRemove.value.show();
			const destroy = (user) => {
				AccountGroupUserRepository.destroy(props.group.id, props.user.id).then(() => {
					emit('reload');
					offcanvas.value.offcanvas.hide();
				}).catch((e) => catchError(e, true));
			}

			const update = (data) => {
				AccountGroupUserRepository.update(props.group.id, props.user.id, data).then(() => {
					emit('reload');
					offcanvas.value.offcanvas.hide();
				}).catch((e) => catchError(e, true));
			}

			return { offcanvas, show, modalRemove, remove, destroy, update };
		}
	}
</script>