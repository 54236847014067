import router from '@/router';
import _ from 'lodash';
import AuthRepository from '@/repositories/AuthRepository';
import composableForm from '@/composables/form';
import composableRedirect from '@/composables/redirect';

const { catchError } = composableForm();
const { redirectNext } = composableRedirect();

export default {
	namespaced: true,
	state: () => ({
		status: 'guest',
		user: null,
		token: null,
		searches: []
	}),
	getters: {
		token(state) {
			return state.token;
		},
		hasUser(state) {
			return (state.status == 'authenticated');
		},
		user(state) {
			return state.user;
		},
		searches(state) {
			return state.searches;
		}
	},
	actions: {
		status({ commit }, status) {
			commit('setStatus', status);
		},
		signup({ commit }, data) {
			return AuthRepository.signup(data).then((response) => {
				commit('setToken', response.data);
			});
		},
		login({ commit }, credentials) {
			return AuthRepository.login(credentials).then((response) => {
				commit('setToken', response.data);
			});
		},
		otp({ commit }, data) {
			return AuthRepository.loginOtp(data).then(response => {
				commit('setToken', response.data);
			});
		},
		loginSocial({ commit }, data) {
			return AuthRepository.loginSocial(data.provider, data.data).then(response => {
				commit('setToken', response.data);
			});
		},
		logout({ commit }) {
			return AuthRepository.logout().finally(() => {
				commit('logout');
				redirectNext();
			});
		},
		me({ commit }) {
			return AuthRepository.user().then((response) => {
				commit('setUser', _.omit(response.data, 'searches'));
				commit('setSearches', response.data.searches);
				commit('setStatus', 'authenticated');

				return response.data;
			}).catch((e) => {
				if(e.response.status == 401) router.push({ name: 'auth.logout' });
				catchError(e, true);
			});
		},
		setUser({ commit }, data) {
			commit('setUser', data);
		},
		setToken({ commit }, data) {
			commit('setToken', data);
		},
		setSearches({ commit }, data) {
			commit('setSearches', data);
		},
		upsertSearch({ dispatch, state }, data) {
			var searches = _.cloneDeep(state.searches),
				index = _.findIndex(searches, { 'id': data.id });

			if(index !== -1) _.merge(searches[index], data);
			else searches.push(data);

			dispatch('setSearches', searches);
		},
		removeSearch({ dispatch, state }, data) {
			var searches = _.cloneDeep(state.searches);
			_.remove(searches, { 'id': data.id });

			dispatch('setSearches', searches);
		}
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setSearches(state, searches) {
			state.searches = searches;
		},
		loadToken(state, aToken) {
			state.token = aToken;
		},
		loadUser(state, anUser) {
			state.user = anUser;
		},
		setStatus(state, status) {
			state.status = status;
		},
		logout(state) {
			state.status = 'guest';
			state.token = null;
			state.user = null;
			state.searches = [];
		}
	}
};