<template>
	<CommonFormInputText v-model="config.url" title="Dirección del archivo JSON" placeholder="Ingrese la dirección" />
</template>

<script>
	import { ref, watch } from 'vue';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: Object
		},
		setup(props, { emit }) {
			const config = ref(props.modelValue);

			watch(config.value, (newValue) => {
				emit('update:modelValue', newValue);
			});

			return { config };
		}
	}
</script>