<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection :text="(route.params.id) ? 'Editar grupo' : 'Crear grupo'" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper :submitText="(route.params.id) ? 'Guardar' : 'Crear'" :cancelUrl="{ name: 'account.group.index' }" :submitting="submitting" :errors="validationExternal" @submit="submit" v-if="!loading">
				<template #body>
					<CommonFormInputText v-model="group.name" title="Nombre" placeholder="Ingrese el nombre" :focus="true" :validation="validation.name" />
					<CommonFormInputTextarea v-model="group.description" title="Descripción" subtitle="Opcional" placeholder="Ingrese una descripción" />
					<CommonFormInputImage v-model="group.logo" :imageUrl="group?.logo_url" title="Logo" :validation="validation.logo" :rounded="true" @remove="removeImage" />
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { reactive, onBeforeMount } from 'vue';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AccountGroupRepository from '@/repositories/Account/GroupRepository';

	export default {
		setup() {
			const { loading, submitting, validationExternal, catchError } = composableForm();
			const route = useRoute();

			const group = reactive({
				name: null,
				description: null,
				logo: null,
				logo_url: null
			});

			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, group);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				if(route.params.id != undefined) {
					await AccountGroupRepository.update(route.params.id, group).then((response) => {
						router.push({ name: 'account.group.show', params: { id: response.data.id }});
					}).catch(catchError);
				}else{
					await AccountGroupRepository.store(group).then((response) => {
						router.push({ name: 'account.group.show', params: { id: response.data.id }});
					}).catch(catchError);
				}

				submitting.value = false;
			}

			const removeImage = () => {
				group.logo_url = null;
			}

			onBeforeMount(async () => {
				if(route.params.id != undefined) {
					await AccountGroupRepository.show(route.params.id).then((response) => {
						group.name = response.data.name;
						group.description = response.data.description;
						group.logo_url = response.data.logo_url;
					}).catch((e) => {
						catchError(e, true);
						router.push({ name: `account.group.index` });
					});
				}

				loading.value = false;
			});

			return { route, loading, group, submit, removeImage, submitting, validation, validationExternal };
		}
	}
</script>