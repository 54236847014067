<template>
	<LayoutFormInputGeneral title="Información adicional">
		<div class="table-responsive">
			<table class="table table-sm">
				<tbody v-if="showData">
					<tr v-if="extras?.brand">
						<td class="px-1 text-sm">Marca</td>
						<td class="px-1 text-sm text-end">{{ extras.brand }}</td>
					</tr>
					<tr v-if="extras?.code">
						<td class="px-1 text-sm">Código</td>
						<td class="px-1 text-sm text-end">{{ extras.code }}</td>
					</tr>
					<tr v-if="(extras?.tags ?? []).length > 0">
						<td class="px-1 text-sm">Tags</td>
						<td class="px-1 text-sm text-end">{{ extras.tags.join(', ') }}</td>
					</tr>
					<tr v-if="extras?.last_season != undefined">
						<td class="px-1 text-sm">Temporada</td>
						<td class="px-1 text-sm text-end">{{ $env.FIELDS.last_season[extras.last_season]?.description ?? '-' }}</td>
					</tr>
					<tr v-if="extras?.gender">
						<td class="px-1 text-sm">Género</td>
						<td class="px-1 text-sm text-end">{{ $env.FIELDS.genders[extras.gender]?.description ?? '-' }}</td>
					</tr>
					<tr v-if="extras?.age_group">
						<td class="px-1 text-sm">Edad</td>
						<td class="px-1 text-sm text-end">{{ $env.FIELDS.age_groups[extras.age_group] ?? '-' }}</td>
					</tr>
					<tr v-if="extras?.age">
						<td class="px-1 text-sm">Antiguedad</td>
						<td class="px-1 text-sm text-end">{{ extras?.age }} años</td>
					</tr>
					<tr v-if="extras?.areas?.total">
						<td class="px-1 text-sm">Superficie total</td>
						<td class="px-1 text-sm text-end">{{ extras?.areas.total }} m2</td>
					</tr>
					<tr v-if="extras?.areas?.covered">
						<td class="px-1 text-sm">Superficie cubierta</td>
						<td class="px-1 text-sm text-end">{{ extras?.areas.covered }} m2</td>
					</tr>
					<tr v-if="extras?.rooms?.total">
						<td class="px-1 text-sm">Cantidad de ambientes</td>
						<td class="px-1 text-sm text-end">{{ extras.rooms.total }}</td>
					</tr>
					<tr v-if="extras?.rooms?.bedrooms">
						<td class="px-1 text-sm">Dormitorios</td>
						<td class="px-1 text-sm text-end">{{ extras.rooms.bedrooms }}</td>
					</tr>
					<tr v-if="extras?.rooms?.bathrooms">
						<td class="px-1 text-sm">Baños</td>
						<td class="px-1 text-sm text-end">{{ extras.rooms.bathrooms }}</td>
					</tr>
					<tr v-if="extras?.parkings">
						<td class="px-1 text-sm">Cocheras</td>
						<td class="px-1 text-sm text-end">{{ extras.parkings }}</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td class="px-1 text-sm" colspan="2">No hay datos.</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			extras: Object
		},
		setup(props) {
			const properties = ['brand', 'code', 'gender', 'age_group', 'age', 'areas.total', 'areas.covered', 'rooms.total', 'rooms.bedrooms', 'rooms.bathrooms', 'parkings'];
			const showData = computed(() => {
				return _.some(properties, prop => !_.isNil(eval(`props.extras.${prop}`)));
			});

			return { showData };
		}
	}
</script>