<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Invitar al grupo" id="common-modal-invitation">
		<template #body>
			<p class="text-md text-break lh-xs">Se generará un enlace que podrás compartir con quien desees. Por seguridad, cada invitación será de un sólo uso y tendrá una validez de 24hs, si deseas invitar a muchas personas debes enviar una invitación a cada una.</p>
		</template>
		<template #footer>
			<button type="button" class="btn rounded-pill w-100 btn-primary" :disabled="submitting" @click="invite">Invitar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref } from 'vue';
	import router from '@/router';
	import { useToast } from 'vue-toastification';
	import composableForm from '@/composables/form';
	import AccountGroupUserRepository from '@/repositories/Account/GroupUserRepository';

	export default {
		props: {
			group: Object
		},
		setup(props) {
			const { submitting, catchError } = composableForm();
			const toast = useToast();
			const offcanvas = ref(null);

			const invite = async () => {
				if(submitting.value) return;
				submitting.value = true;

				if(!navigator.canShare) toast.error(`Tu dispositivo no permite compartir contenido.`);
				else await AccountGroupUserRepository.invite(props.group.id).then((response) => {
					navigator.share({
						title: 'Invitación',
						text: `Te invito a unirte al grupo ${props.group.name}`,
						url: `${window.location.origin}${router.resolve({ name: 'invitation.index', params: { uuid: response.data.uuid }}).href}`
					});
				}).catch((e) => catchError(e, true));

				submitting.value = false;
			}

			const show = () => offcanvas.value.offcanvas.show();

			return { offcanvas, invite, show, submitting };
		}
	}
</script>