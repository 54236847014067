import { computed } from 'vue';
import store from '@/store';
import router from '@/router';

export default function() {
	const redirectNextInfo = computed(() => store.getters['redirect/next']);
	const redirectSearchInfo = computed(() => store.getters['redirect/search']);

	const redirectBack = () => {
		if(!!window?.history?.state?.back) return router.go(-1);
		else return router.push({ name: 'home' });
	}

	const hasRedirectNext = () => {
		return !!redirectNextInfo.value;
	}

	const redirectNext = () => {
		if(redirectNextInfo.value) return router.push(redirectNextInfo.value);
		else return router.push({ name: 'home' });
	}

	const redirectSearch = () => {
		if(redirectSearchInfo.value) return router.push(redirectSearchInfo.value);
		else return router.push({ name: 'search.index' });
	}

	return { redirectNextInfo, redirectBack, hasRedirectNext, redirectNext, redirectSearch };
}