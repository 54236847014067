<template>
	<router-link :to="{ name: 'home' }" class="hstack gap-2 align-items-center">
		<AppCommonImage size="md" />
		<div class="flex-fill lh-xs text-limit">
			<div class="h3 text-break text-truncate">{{ viteAppName }}</div>
			<div class="text-muted text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			subtitle: {
				type: String,
				default: null
			}
		},
		setup() {
			const viteAppName = import.meta.env.VITE_APP_NAME;

			return { viteAppName };
		}
	}
</script>