<template>
	<a href="javascript:void(0);" class="link-dark hstack gap-2 align-items-center py-1 ps-3 pe-2 border rounded-pill shadow-2-hover bg-white" @click="identify">
		<i class="bi bi-list text-lg"></i>
		<UserAvatarRoundedNone size="xs" />
	</a>
</template>

<script>
	import { getCurrentInstance } from 'vue';

	export default {
		setup() {
			const instance = getCurrentInstance();
			const identify = () => instance.proxy.$identify();

			return { identify };
		}
	}
</script>