<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Instalá la aplicación" id="app-permission-install">
		<template #body>
			<div class="vstack">
				<h3 class="fw-bolder text-dark">¿Te gusta nuestra plataforma?</h3>
				<p class="text-md text-muted lh-sm">Instalá nuestra aplicación progresiva y obtené una mejor experiencia en <b>{{ viteAppName }}</b>.</p>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn btn-neutral rounded-pill" @click="dismiss">Ahora no</button>
			<button type="button" class="btn btn-primary rounded-pill" @click="accept">Instalar</button>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, watch, onBeforeUnmount } from 'vue';
	import { VueCookieNext } from 'vue-cookie-next';

	export default {
		setup() {
			const viteAppName = import.meta.env.VITE_APP_NAME;
			const deferredPrompt = ref(null);
			const timeout = ref(null);

			const offcanvas = ref(null);
			const permissionInstallShow = () => offcanvas.value.offcanvas.show();
			const permissionInstallHide = () => offcanvas.value.offcanvas.hide();

			window.addEventListener('appinstalled', () => {
				deferredPrompt.value = null;
			});

			window.addEventListener('beforeinstallprompt', (e) => {
				e.preventDefault();
				if(!VueCookieNext.isCookieAvailable('add-to-home-screen')) deferredPrompt.value = e;
			});

			const dismiss = async () => {
				VueCookieNext.setCookie('add-to-home-screen', null, { expire: '10d' });
				deferredPrompt.value = null;
				permissionInstallHide();
			}

			const accept = async () => {
				permissionInstallHide();
				deferredPrompt.value.prompt();
			}

			onBeforeUnmount(() => { if(timeout.value) clearTimeout(timeout.value); });

			const previousShow = () => {
				if(document.querySelector('.modal.show') || document.querySelector('.offcanvas.show')) timeout.value = setTimeout(previousShow, 5000);
				else permissionInstallShow();
			}

			watch(deferredPrompt, (newValue) => {
				if(newValue) timeout.value = setTimeout(previousShow, 120000);
			});

			return { viteAppName, offcanvas, dismiss, accept };
		}
	}
</script>