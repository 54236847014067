<template>
	<div class="card text-bg-white border border-2 border-dark rounded-4">
		<div class="card-body vstack gap-3" v-if="active">
			<div class="hstack align-items-center justify-content-between">
				<div class="h3">{{ title }}</div>
				<a href="javascript:void(0);" class="link-danger" @click="empty" v-if="completed"><i class="bi bi-trash text-sm pe-1"></i>Quitar</a>
				<div class="text-muted" v-else>{{ subtitle }}</div>
			</div>
			<div class="row g-1">
				<slot />
			</div>
		</div>
		<div class="card-body hstack gap-5 align-items-center justify-content-between" v-else>
			<div>{{ title }}</div>
			<a href="javascript:void(0);" class="link-primary stretched-link text-truncate" @click="setStep" v-if="completed">{{ selected }}</a>
			<a href="javascript:void(0);" class="text-muted stretched-link" @click="setStep" v-else>{{ subtitle }}</a>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['empty', 'setStep'],
		props: {
			title: String,
			subtitle: String,
			active: Boolean,
			completed: Boolean,
			selected: String
		},
		setup(props, { emit }) {
			const empty = () => emit('empty');
			const setStep = () => emit('setStep');

			return { empty, setStep };
		}
	}
</script>