<template>
	<LayoutClean class="vstack">
		<div class="container-md mh-100 h-100 overflow-hidden">
			<UserIndexImagesGrid :images="user.images" />
			<UserIndexDescription :user="user" />
		</div>
	</LayoutClean>
</template>

<script>
	export default {
		name: 'ViewsUserHome',
		props: {
			user: Object
		}
	}
</script>