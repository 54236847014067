<template>
	<SearchFilter :group="group" :prefilters="group?.filters" />
</template>

<script>
	export default {
		props: {
			group: Object
		}
	}
</script>