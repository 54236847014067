<template>
	<LayoutFormInputFilter :id="`step-${currentStep}`" title="Tipo de publicación" subtitle="Obligatorio" :active="step == currentStep" :completed="!!modelValue" :selected="optionDescription" @empty="empty" @setStep="setStep">
		<div class="col-6 col-sm-4 col-md-3" v-for="(option, index) in $env.FIELDS.types" v-bind:key="index">
			<div class="form-item-checkable">
				<input class="form-item-check" type="radio" name="project-view" :value="index" :checked="modelValue == index" @input="setValue" :id="index">
				<label class="form-item w-100" :for="index">
					<span class="form-item-click form-control text-muted p-4 w-100 vstack gap-1 justify-content-between lh-xs">
						<i class="fi text-xl" :class="`fi-rr-${option.icon}`"></i>
						{{ option.description }}
					</span>
				</label>
			</div>
		</div>
	</LayoutFormInputFilter>
</template>

<script>
	import { ref, computed, inject } from 'vue';

	export default {
		emits: ['update:modelValue', 'setStep'],
		props: {
			modelValue: String,
			step: String
		},
		setup(props, { emit }) {
			const currentStep = 'type';
			const $env = inject('$env');

			const setValue = (event) => emit('update:modelValue', event.target.value);
			const empty = () => emit('update:modelValue', null);
			const setStep = () => emit('setStep', currentStep);

			const optionDescription = computed(() => {
				if(!props.modelValue) return null;
				else return $env.FIELDS.types[props.modelValue].description;
			});

			return { currentStep, setValue, empty, setStep, optionDescription };
		}
	}
</script>