import store from '@/store';
import composableForm from '@/composables/form';
import ConfigurationRepository from '@/repositories/ConfigurationRepository';

const setConfiguration = (app, config) => {
	app.config.globalProperties.$env = config;
	app.provide('$env', config);
}

export const loadConfiguration = async (app) => {
	const { catchError } = composableForm();
	const info = store.getters['config/info'];

	if(info) setConfiguration(app, info);
	else await ConfigurationRepository.index().then((response) => {
		store.dispatch('config/setInfo', response.data);
		setConfiguration(app, response.data);
	}).catch(catchError);
};