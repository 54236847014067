import Repository from './Repository';

const resource = '/map';

export default {
	autocomplete(payload) {
		return Repository.post(`${resource}/autocomplete`, payload);
	},
	place(payload) {
		return Repository.post(`${resource}/place`, payload);
	}
}