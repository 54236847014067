import Repository from '../Repository';
import { useToast } from 'vue-toastification';
import store from '@/store';

const resource = '/account/publication';
const toast = useToast();

export default {
	index(params) {
		return Repository.get(`${resource}`, { params: params });
	},
	show(id) {
		return Repository.get(`${resource}/${id}`);
	},
	update(id, payload) {
		return Repository.put(`${resource}/${id}`, payload).then((response) => {
			store.dispatch('auth/me');
			toast.success(`Publicación actualizada correctamente.`);
			return response;
		});
	}
}