<template>
	<tr>
		<td>{{ dateFormatted }}</td>
		<td>{{ user.name }}</td>
		<td>{{ user.email }}</td>
		<td class="text-end py-0">
			<div class="dropstart">
				<button class="btn btn-md btn-square shadow-none border-0 rounded-circle text-md text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
				<div class="dropdown-menu px-0">
					<router-link :to="{ name: 'admin.user.form', params: { id: user.id }}" class="dropdown-item rounded-0">Editar</router-link>
					<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';

	export default {
		emits: ['remove'],
		props: {
			user: Object
		},
		setup(props, { emit }) {
			const remove = () => emit('remove', props.user);
			const dateFormatted = computed(() => moment(props.user.created_at).format('MMMM D [de] YYYY, HH:mm'));

			return { remove, dateFormatted };
		}
	}
</script>