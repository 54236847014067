<template>
	<LayoutFormInputGeneral title="Publicado por">
		<ul class="list-group list-group-flush">
			<li class="list-group-item py-2" v-for="user in users" v-bind:key="user.id">
				<UserCardSmall :user="user" />
			</li>
		</ul>
	</LayoutFormInputGeneral>
</template>

<script>
	export default {
		props: {
			users: Array
		}
	}
</script>