<template>
	<div>
		<div class="list-group rounded-4">
			<div class="list-group-item d-flex align-items-center py-2 pe-2" v-for="(filter, index) in filters" :key="index">
				<div class="text-muted lh-xs">{{ filter.title }}</div>
				<div class="ms-auto text-end">
					<a href="javascript:void(0);" class="btn btn-link text-muted" @click="remove(index)"><i class="bi bi-trash"></i></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['remove'],
		props: {
			filters: Array
		},
		setup(props, { emit }) {
			const remove = (index) => emit('remove', index);

			return { remove };
		}
	}
</script>