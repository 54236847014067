<template>
	<tr>
		<td class="position-relative lh-xs">
			<div class="vstack gap-1">
				<router-link :to="{ name: 'search.result', params: { uuid: search.uuid }}" class="link-dark text-md fw-bold stretched-link">{{ search.title }}</router-link>
				<div class="text-sm" v-if="search.user">En la cuenta <router-link :to="{ name: 'user.search.index', params: { slug: search.user.slug }}" class="link-dark fw-bold position-relative stretched-link">{{ search.user.name }}</router-link> {{ dateDiff }}</div>
				<div class="text-sm" v-else-if="search.group">En el grupo <router-link :to="{ name: 'group.search.index', params: { id: search.group.id }}" class="link-dark fw-bold position-relative stretched-link">{{ search.group.name }}</router-link> {{ dateDiff }}</div>
				<div class="text-sm" v-else>Creada {{ dateDiff }}</div>
			</div>
		</td>
		<td class="text-end py-0">
			<div class="dropstart">
				<button class="btn btn-md btn-square shadow-none border-0 rounded-circle text-md text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
				<div class="dropdown-menu px-0">
					<router-link :to="{ name: 'search.result', params: { uuid: search.uuid }}" class="dropdown-item rounded-0 fw-bold">Continuar búsqueda</router-link>
					<router-link :to="{ name: 'account.search.filtered', params: { uuid: search.uuid }}" class="dropdown-item rounded-0 fw-bold">Ver resulatos</router-link>
					<div class="dropdown-divider"></div>
					<a href="javascript:void(0)" class="dropdown-item rounded-0" @click="remove">Eliminar</a>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
	import { computed } from 'vue';
	import moment from 'moment';
	import 'moment/locale/es';

	export default {
		emits: ['remove'],
		props: {
			search: Object
		},
		setup(props, { emit }) {
			moment.locale('es');
			const dateDiff = moment(props.search.created_at).fromNow();
			const remove = () => emit('remove', props.search);

			return { dateDiff, remove };
		}
	}
</script>