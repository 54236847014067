<template>
	<LayoutFormInputGeneral title="Variedades disponibles">
		<div class="table-responsive">
			<table class="table table-sm table-hover">
				<thead>
					<tr>
						<th class="px-1 text-sm">Color</th>
						<th class="px-1 text-sm text-end">Talles</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, index) in stockFiltered" v-bind:key="index">
						<td class="px-1 text-sm">{{ data.color ?? 'Sin especificar' }}</td>
						<td class="px-1 text-sm text-end">{{ data.sizes ?? 'Sin especificar' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			stock: Array
		},
		setup(props) {
			const stockFiltered = computed(() => {
				return _.map(_.groupBy(props.stock, 'color'), (items, color) => {
					let sizes = _.uniq(_.map(_.filter(items, (o) => !!o.size), 'size'));

					return {
						color: color === 'null' ? null : color,
						sizes: (sizes.length == 0) ? null : sizes.join(', ')
					}
				});
			});

			return { stockFiltered };
		}
	}
</script>