<template>
	<div class="d-flex align-items-center justify-content-between px-2 px-sm-14 px-md-24 px-lg-32">
		<button type="button" class="btn btn-square border-2 border-dark text-dark rounded-circle shadow p-2" @click="redirectBack" v-if="!hidden.includes('back')">
			<i class="bi bi-chevron-left text-xl"></i>
		</button>
		<button type="button" class="btn btn-square btn-lg border-2 border-danger text-danger rounded-circle shadow p-3" @click="decision(false)" :disabled="disabled.includes('decision')" v-if="!hidden.includes('decision')">
			<i class="bi bi-x-lg text-xl"></i>
		</button>
		<button type="button" class="btn btn-square border-2 border-warning text-warning rounded-circle shadow p-2 pb-1" @click="undo" :disabled="disabled.includes('undo')" v-if="!hidden.includes('undo')">
			<i class="bi bi-arrow-counterclockwise text-xl"></i>
		</button>
		<button type="button" class="btn btn-square border-2 border-info text-info rounded-circle shadow p-2" @click="map" :disabled="disabled.includes('map')" v-if="!hidden.includes('map') && hasProperties">
			<i class="bi bi-map text-lg"></i>
		</button>
		<SearchPartButtonLike @decision="decision" :disabled="disabled.includes('decision')" v-if="!hidden.includes('decision')" />
		<button type="button" class="btn btn-square border-2 border-info text-info rounded-circle shadow p-2 position-relative" @click="option" v-if="!hidden.includes('option')">
			<i class="bi bi-list text-2xl"></i>
			<span class="badge bg-danger list-count rounded-pill position-absolute top-0 end-0 mt-n2 me-n1" v-if="count > 0">{{ count }}</span>
		</button>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';

	export default {
		emits: ['decision', 'undo', 'option', 'map'],
		props: {
			search: Object,
			hidden: {
				type: Array,
				default: []
			},
			disabled: {
				type: Array,
				default: []
			},
			count: Number
		},
		setup(props, { emit }) {
			const { redirectBack } = composableRedirect();
			const hasProperties = computed(() => _.some(props.search?.filters, { type: 'property' }));
			const decision = (like, is_super) => emit('decision', like, is_super);
			const undo = () => emit('undo');
			const option = () => emit('option');
			const map = () => emit('map');

			return { redirectBack, hasProperties, decision, undo, option, map };
		}
	}
</script>

<style scoped>
	.list-count {
		padding: 1px 5px !important;
		vertical-align: middle;
	}
</style>