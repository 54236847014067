<template>
	<Marker :options="options" @click="click" />
</template>

<script>
	import { computed } from 'vue';
	import { Marker } from 'vue3-google-map';
	import assetsImgIconsMarkerPrimary from '@/assets/img/icons/marker-primary.png';

	export default {
		components: { Marker },
		emits: ['click'],
		props: {
			position: Object
		},
		setup(props, { emit }) {
			const options = computed(() => {
				return {
					position: props.position,
					icon: {
						url: assetsImgIconsMarkerPrimary,
						scaledSize: {
							width: 24,
							height: 24
						},
						anchor: new google.maps.Point(12, 12)
					}
				};
			});

			const click = () => emit('click');

			return { options, click };
		}
	}
</script>