import Repository from '../Repository';
import { useToast } from 'vue-toastification';

const resource = '/admin/user';
const toast = useToast();

export default {
	index(params) {
		return Repository.get(`${resource}`, { params: params });
	},
	show(id) {
		return Repository.get(`${resource}/${id}`);
	},
	update(id, payload) {
		return Repository.put(`${resource}/${id}`, payload).then((response) => {
			toast.success(`Usuario actualizado correctamente.`);
			return response;
		});
	},
	destroy(id) {
		return Repository.delete(`${resource}/${id}`).then((response) => {
			toast.success(`Usuario eliminado correctamente.`);
			return response;
		});
	}
}