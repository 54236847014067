<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Listado de publicaciones" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-10">
			<LayoutTableMain :results="results" :loading="loading" :responsive="false" @goPage="goPage">
				<template #filters>
					<div class="input-group input-group-sm rounded-pill">
						<span class="input-group-text bg-white rounded-start-pill pe-0" id="basic-addon1"><i class="bi bi-search"></i></span>
						<input type="text" v-model="filters.search" class="form-control border-start-0 rounded-end-pill" placeholder="Buscar..." />
					</div>
					<router-link :to="{ name: 'account.publication.synchronizer' }" class="btn btn-sm btn-dark rounded-pill flex-shrink-0">Sincronización</router-link>
				</template>
				<template #body>
					<PublicationTableRow v-for="data in results?.data" v-bind:key="data.id" :publication="data" @select="select" />
				</template>
			</LayoutTableMain>
		</main>

		<AccountPublicationModalPriority ref="modalPriority" @success="getResults" />
	</LayoutMain>
</template>

<script>
	import { ref, reactive, watch, onMounted } from 'vue';
	import composableForm from '@/composables/form';
	import AccountPublicationRepository from '@/repositories/Account/PublicationRepository';

	export default {
		setup() {
			const { loading, catchError } = composableForm();
			const results = ref({});
			const filters = reactive({
				search: null,
				orderBy: 'title',
				orderMethod: 'asc',
				take: 20,
				page: 1
			});

			const modalPriority = ref(null);
			const select = (data) => {
				modalPriority.value.show(data);
			}

			const getResults = async () => {
				loading.value = true;

				await AccountPublicationRepository.index(filters).then((response) => {
					results.value = response.data;
				}).catch((e) => {
					results.value = {};
					catchError(e, true);
				});

				loading.value = false;
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(getResults);

			return { loading, filters, results, goPage, getResults, modalPriority, select };
		}
	}
</script>