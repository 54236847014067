<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Editar usuario" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper submitText="Guardar" :cancelUrl="{ name: 'admin.user.index' }" :submitting="submitting" :errors="validationExternal" @submit="submit" v-if="!loading">
				<template #body>
					<CommonFormInputReadonly v-model="target.name" title="Nombre" />
					<CommonFormInputReadonly v-model="target.email" title="Email" />
					<CommonFormInputCheckbox v-model="target.permissions" title="Permisos" :options="$env.MODEL.user.permissions" />
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { reactive, computed, onBeforeMount } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import _ from 'lodash';
	import moment from 'moment';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AdminUserRepository from '@/repositories/Admin/UserRepository';

	export default {
		setup() {
			const { loading, submitting, validationExternal, catchError } = composableForm();
			const user = computed(() => store.getters['auth/user']);
			const createdAt = computed(() => moment(target?.created_at).format('LL'));
			const updatedAt = computed(() => moment(target?.updated_at).format('LL'));
			const route = useRoute();
			const target = reactive({});
			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, target);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				await AdminUserRepository.update(route.params.id, target).then(() => {
					router.push({ name: 'admin.user.index' });
					if(route.params.id == user.value.id) store.dispatch('auth/me');
				}).catch(catchError);

				submitting.value = false;
			}

			onBeforeMount(async () => {
				if(route.params.id != undefined) {
					await AdminUserRepository.show(route.params.id).then((response) => {
						Object.assign(target, response.data);
						if(target.permissions == null) target.permissions = [];
					}).catch((e) => {
						catchError(e);
						router.push({ name: `user.index` });
					});
				}

				loading.value = false;
			});

			return { createdAt, updatedAt, loading, target, submit, submitting, validation, validationExternal };
		}
	}
</script>