<template>
	<form class="vstack gap-5" @submit.prevent="submit" autocomplete="off">
		<CommonFormInputReadonly v-model="form.email" title="Email" />
		<CommonFormInputText v-model="form.name" title="Nombre" placeholder="Ingrese su nombre" :focus="true" :validation="validation.name" />
		<div class="fw-bold lh-sm">Al continuar aceptas nuestros <router-link :to="{ name: 'legal.terms' }" target="_blank" class="link-primary">términos y condiciones</router-link> y <router-link :to="{ name: 'legal.privacy' }" target="_blank" class="link-primary">políticas de privacidad</router-link>.</div>
	</form>
</template>

<script>
	import { reactive } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';

	export default {
		emits: ['updateStep'],
		props: {
			email: String
		},
		setup(props, { emit }) {
			const { submitting, catchError } = composableForm();

			const form = reactive({
				email: props.email,
				name: null,
				searches: _.map(store.getters['auth/searches'], 'uuid')
			});

			const validation = useVuelidate({
				name: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(150)
				}
			}, form);

			const submit = async () => {
				if(submitting.value) return;
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await store.dispatch('auth/signup', form).then((response) => {
					emit('updateStep', 'logged');
				}).catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { validation, form, submitting, submit };
		}
	}
</script>