<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="form-check" :class="{'form-check-inline': (type == 'inline'), 'is-invalid': validation?.$error}" v-for="(text, index) in options" v-bind:key="index">
			<input type="checkbox"
					:id="`${inputId}-${index}`"
					:value="index"
					v-model="inputValue"
					@change="emitInput"
					class="form-check-input"
					:class="{'is-invalid': validation?.$error}">
			<label :for="`${inputId}-${index}`" class="form-check-label">{{ text }}</label>
		</div>
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref } from 'vue';

	export default {
		emits: ['update:modelValue'],
		props: {
			modelValue: {
				type: Object,
				default: []
			},
			title: String,
			subtitle: String,
			validation: Object,
			options: {
				type: Object,
				default: {}
			},
			type: String
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);

			const emitInput = (event) => {
				emit('update:modelValue', inputValue.value);
			};

			return { inputId, inputValue, emitInput };
		}
	}
</script>