<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Contactar usuario" id="user-modal-contact">
		<template #body>
			<div class="vstack gap-5">
				<ul class="list-group list-group-flush rounded-4" v-if="user?.id">
					<template v-if="user.contact_types">
						<li class="list-group-item px-0 py-3 lh-sm" v-if="user.contact_types.includes('email') && user.email">
							<a :href="`mailto:${user.email}`" class="fw-bold link-dark stretched-link">Contactar por email</a>
							<small class="d-block fw-normal">{{ user.email }}</small>
						</li>
						<div class="list-group-item px-0 py-3 lh-sm" v-if="user.contact_types.includes('whatsapp') && user.phone">
							<a :href="`https://wa.me/${phoneFormatted.number}`" target="_blank" class="fw-bold link-dark stretched-link">Contactar por Whatsapp</a>
							<small class="d-block fw-normal">{{ phoneFormatted.nationalNumber }}</small>
						</div>
						<li class="list-group-item px-0 py-3 lh-sm" v-if="user.contact_types.includes('phone') && user.phone">
							<a :href="`tel:${phoneFormatted.number}`" class="fw-bold link-dark stretched-link">Llamar por teléfono</a>
							<small class="d-block fw-normal">{{ phoneFormatted.nationalNumber }}</small>
						</li>
					</template>
					<template v-else>
						<li class="list-group-item px-0 py-3 lh-sm">
							No se encontraron datos de contacto.
						</li>
					</template>
				</ul>
			</div>
		</template>
	</LayoutOffcanvasStandard>
</template>

<script>
	import { ref, computed } from 'vue';
	import { parsePhoneNumber } from 'libphonenumber-js';

	export default {
		props: {
			user: Object
		},
		setup(props) {
			const offcanvas = ref(null);
			const show = (data) => offcanvas.value.offcanvas.show();
			const phoneFormatted = computed(() => {
				return (props.user?.phone) ? parsePhoneNumber(props.user.phone) : null
			});

			return { offcanvas, show, phoneFormatted };
		}
	}
</script>